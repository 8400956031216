import { every } from "lodash"
import { generateCodeFrame } from "vue-template-compiler"
import PromotionDetail from "../views/promotion/PromotionDetail.vue"
import { template } from "lodash"

const messages = {
    manageMenuRanking: 'จัดการเมนูขายหน้าร้าน',
    moreproduct: 'สินค้าเพิ่มเติม',
    stepToIntergrate: 'ดูขั้นตอนการเชื่อมต่อ Grab ได้ที่ ',
    stepGrabandPOS: 'ขั้นตอนการเชื่อมต่อ Grab และ Silom POS',
    warningBeforeIntergration: 'คำเตือน! ก่อนทำงานเชื่อมต่อ',
    warningBeforeIntergrationDes: 'กดปุ่ม "ส่งออกเมนู" ทุกครั้ง เพื่อดึงเมนูบน GrabFood มายังเครื่อง POS ถ้าคุณกดข้ามขั้นตอน เมนูบน GrabFood ของคุณจะหายไป และไม่สามารถกู้คืนข้อมูลได้',
    connectGrab: 'คลิกเพื่อเชื่อมต่อ Grab และ POS',
    duration: '',
    openOrderTxt: 'รับออเดอร์  เข้าเครื่อง POS',
    openOrderDes: 'เปิด/ปิด รับออเดอร์เข้าเครื่อง POS (คุณยังสามารถรับออเดอร์บนแอปพลิเคชัน Merchant ได้ตามปกติ',
    openOrder: 'เปิด รับออเดอร์',
    closeOrder: 'ปิด รับออเดอร์',
    duration30M: 'ปิดชั่วคราว 30 นาที',
    duration1H: 'ปิดชั่วคราว 1 ชั่วโมง',
    duration24H: 'ปิดชั่วคราว 24 ชั่วโมง',
    shopOnOff: 'เปิด/ปิด ร้านค้า',
    deliveryShopSetting: 'เปิด/ปิด เดลิเวอรี่',
    decShopDelivery: 'ตั้งค่าเวลาเปิดปิดร้าน สำหรับเดลิเวอรี่ และสถานะการรับออเดอร์ของเดลิเวอรี่',
    pleaseFillShopName: 'กรุณากรอกชื่อร้านค้า',
    pleaseFillBranchName: 'กรุณากรอกชื่อสาขา',
    pleaseFillBusinessType: 'กรุณาเลือกประเภทธุรกิจ',
    deliveryGrab: 'จัดการเมนูสำหรับ Grab',
    grabIntergrationDes: 'การเชื่อมต่อระหว่าง Grab และ Silom POS ช่วยให้ร้านค้าจัดการออเดอร์จาก Grab ได้อย่างอัตโนมัติในระบบ POS  <br> ทำให้ลดความยุ่งยากในการบันทึกข้อมูล เพิ่มความถูกต้องและรวดเร็ว นอกจากนี้ ยังช่วยจัดการสต็อกและยอดขายได้แบบเรียลไทม์ <br/> ทั้งหมดนี้ช่วยเพิ่มประสิทธิภาพการดำเนินงานและสร้างโอกาสในการขยายธุรกิจผ่านช่องทางออนไลน์ได้อย่างเต็มรูปแบบ',
    grabIntergrationDes1: 'ขั้นตอนในการต่อกับ Grab ใช้เวลาประมาณครึ่งชั่วโมงถึง 1 ชั่วโมง ดังนัั้นแนะให้ทำ ในช่วงหลังปิดร้าน',
    grabIntergrationDes2: 'หากคุณยังไม่ได้สมัครใช้งาน แอป GrabMerchant กรุณาสมัครผ่านแอปหรือเว็บไซต์ Grab ให้เรียบร้อยก่อนดำเนินการตามขั้นตอน',
    grabIntergrationDes3: 'หลังการเชื่อมต่อสำเร็จ ขอแนะนำให้สร้างสินค้าเฉพาะจาก Silom POS แทนการสร้างจากฝั่ง GrabMerchant.',
    grabIntergrationDes4: 'แก้ไขข้อมูลทั้งหมดให้เสร็จในครั้งเดียวก่อนอัปโหลด เพราะต้องรอคิวเพื่อให้ Grab มาดึงข้อมูล',
    grabIntergrationDes5: 'เมื่อถึงหน้า Enabling integration ห้ามกดออกจากเว็บหรือเปลี่ยนเมนูเด็ดขาด',
    grabIntergrationDes6: 'เพื่อป้องกันข้อผิดพลาดที่อาจเกิดขึ้นและรับประกันว่าข้อมูลจะถูกซิงค์อย่างถูกต้องและสมบูรณ์',
    grabIntegration: 'เชื่อมต่อ Grab',
    manageStock: 'จัดการสต็อค',
    immediately: 'ทันที',
    everyDay: 'ทุกวัน',
    everyWeek: 'ทุกสัปดาห์',
    maxStock: 'จำนวนสต็อคสินค้าสูงสุด',
    replenishment: 'ระยะเวลาเติมสต็อคสินค้า',
    deliveryStock: 'กำหนดจำนวนสต็อคสินค้า และกำหนดระยะเวลาการเติมสต็อคสินค้า',
    enterStockOndelivery: 'กรอกจำนวนสต็อคสำหรับสินค้า ',
    deliveryMD: 'จัดการข้อมูลสำหรับรายการดิลิเวอร์รี่',
    syncWhenEdit: 'ซิงค์ข้อมูลทุกครั้ง เมื่อมีการแก้ไขข้อมูลสำหรับรายการดิลิเวอร์รี่ เพื่ออัพเดตข้อมูลไปยังระบบของ Grab',
    syncWhenEditProduct: 'หากมีการแก้ไขมูล แต่ยังไม่กดซิงค์ข้อมูล ข้อมูลในระบบของ Grab จะยังไม่ถูกอัพเดต',
    lastSync: 'ซิงค์ล่าสุด',
    setSellingHoursForProduct: 'กำหนดช่วงการขายสำหรับสินค้า เพื่อแสดงสินค้าตามช่วงเวลาขายของ Grab',
    timeSlotOverlap: "ช่วงเวลาไม่สามารถทับซ้อนกันได้ กรุณาปรับช่วงเวลาใหม่ค่ะ",
    maxSlotsReached: "จำนวนช่วงเวลาสูงสุดที่สามารถเพิ่มได้ถึงขีดจำกัดแล้วค่ะ",
    noMoreTimeSlots: "ไม่สามารถเพิ่มช่วงเวลาได้อีกค่ะ",
    salesHours: 'ช่วงเวลาการขาย',
    promotionDashboardMenu: 'รายงานโปรโมชั่น',
    grabDashboardMenu: 'รายงาน Grab',
    confirmDeletSellingHours: 'ยืนยันลบชั่วโมงการขาย',
    dialigDeleteSellingHours: 'คุณต้องการลบเวลาการขายนี้หรือไม่',
    selfPickUp: 'รับที่ร้าน',
    delivery: 'ดิลิเวอร์รี',
    editsalesHoursManagement: 'แก้ไขช่วงเวลาการขาย',
    salesHoursSetting: 'ตั้งค่าช่วงเวลาการขาย',
    addsalesHoursManagement: 'เพิ่มช่วงเวลาการขาย',
    setSelling: 'กำหนดช่วงเวลาขาย',
    txt2SalesHoursSetting: 'โดยสามารถกำหนดช่วงเวลาสำหรับสินค้าได้ที่หน้าจัดการเมนูเดลิเวอร์รี่ ',
    txtSalesHoursSetting: 'เพื่อใช้ในการกำหนดช่วงเวลาการขายสำหรับสินค้าในรายการของแกร็บ  โดยสามารถกำหนดช่วงเวลาการขายได้สูงสุด 3 ช่วง และ 3 ช่วงเวลาต่อวัน',
    txtSalesHoursSettingDes: '- Ex.  ช่วงเช้า <br>  <p class="ml-3">• วันจันทร์ มี 3 ช่วงเวลา ดังนี้ 8:00 - 9:00 , 10:00 - 11:00 , 10:01 - 12:00 </p>',
    fillSaleName: 'กรุณากรอกชื่อเวลาการขาย',
    salesTime: 'เวลาการขาย',
    txtsalesHours: 'ชื่อเวลาการขาย',
    addSalesHours: 'เพิ่มเวลาการขาย',
    salesHoursManagement: 'จัดการเวลาการขาย',

    connectDes: "ผู้ช่วยคนสำคัญ ที่ช่วยเชื่อมต่อคุณให้จัดการธุรกิจได้ง่ายขึ้น เช็คยอดขายทันใจ เปรียบเสมือนอยู่ร้านตลอดเวลา",
    scanQrtoAddLine: "สเเกน QR Code เพื่อเเอดไลน์",
    applyForSilomConnect: "วิธีสมัครใช้งาน Silom Smart Connect",
    allOrders: 'ทั้งหมด',
    successOrders: 'สำเร็จ',
    cancelOrders: 'ยกเลิก',
    topFivePromotion: "โปรโมชันใที่ใช้งานมากที่สุด",
    topFivePromotion: "โปรโมชันที่ใช้งานมากที่สุด",
    promotionUsage: "การใช้โปรโมชัน",
    synchFailDescription: 'เกิดข้อผิดพลาดขณะตรวจสอบสถานะการซิงค์ โปรดลองอีกครั้ง',
    shopPromotion: 'โปรโมชันร้านค้า',
    grabPromotion: 'โปรโมชัน Grab',
    promotionTransection: "ประวัติการใช้งานโปรโมชัน",
    promotionStartDate: "วันที่เริ่มโปรโมชัน",
    PromotionEndDate: "วันที่สิ้นสุดโปรโมชัน",
    promotionRemaining: "โปรโมชันที่เหลือ",
    synchQueueing: "กำลังอยู่ในคิวอัพโหลดข้อมูล",
    synchProcessing: "กำลังประมวลผลข้อมูล",
    synchSuccess: "ซิงค์ข้อมูลสำเร็จ",
    synchFail: "ซิงค์ข้อมูลไม่สำเร็จ",
    totals: "รวม",
    addBom: "เพิ่มส่วนประกอบ",
    activeMat: "ส่วนประกอบที่ใช้งาน",
    addOnActive: "กลุ่มตัวเลือกที่ใช้งาน",
    moredeli: "ราคาดิลิเวอรี่เพิ่มเติม",
    deliveryPrice: "ราคาดิลิเวอร์รี่",
    itemCount: "จำนวนสินค้า",
    grabReportMenu: "รายงาน Grab ",
    promotionReportMenu: "โปรโมชัน",
    deliveryManagement: "จัดการเมนูเดลิเวอร์รี่",
    noData: "ไม่มีข้อมูล",
    orderStatus: "สถานะออเดอร์",
    orderSuccess: "ออเดอร์สำเร็จ",
    orderCancel: "ออเดอร์ยกเลิก",
    orderTotal: "ออร์เดอร์ทั้งหมด",
    totalRevenue: "รายได้ทั้งหมด",
    success: "สำเร็จ",
    hourlySalesReport: "รายงานการขายรายชั่วโมง",
    grabReport: "รายงานยอดขายแกร็บ",
    orderSummary: "สรุปออเดอร์",
    synchSuccess: "การซิงค์ข้อมูลสำเร็จ",
    confirmSynch: "ยืนยันการซิงค์ข้อมูล",
    promotionCode: 'รหัสโปรโมชัน',
    promotionTotal: "จำนวนการใช้",
    txtSalesPromotionReport: "รายงานโปรโมชัน",
    priceOndelivery: "",
    deliveryOnDemand: "ราคาปกติ",
    deliveryScheduled: "ราคาสั่งล่วงหน้า",
    selfPickUpOnDemand: "ราคารับหน้าร้าน",
    dineInOnDemand: "ราคากินที่ร้าน",
    productDes: "รายละเอียดสินค้า",
    salesChannels: "ช่องทางการขาย",
    channelsDescriptionConfig: 'ตั้งค่าช่องทางการขาย สำหรับรายการดิลิเวอร์ลี่',
    isAVGCostCalc: 'คำนวนต้นทุนเฉลี่ยน',
    passwordInvalid: "รหัสผ่านไม่ถูกต้องหรือผู้ใช้งานยังไม่ได้ตั้งรหัสผ่าน",
    emailBadlyFormatted: "รูปแบบอีเมลไม่ถูกต้อง",
    userNotFound: "ไม่พบข้อมูลผู้ใช้งานที่ตรงกับรหัสนี้ ผู้ใช้งานอาจถูกลบไปแล้ว",
    wrongEmail: 'กรุณากรอกอีเมลใหม่อีกครั้ง',
    tryVerifiedEmail: 'ลองยืนยันอีเมลของคุณอีกครั้ง',
    signinNewAcc: 'คุณสาลองยืนยันอีเมลของคุณอีกครั้งมารถเข้าสู่ระบบด้วยบัญชีใหม่ของคุณได้แล้ว',
    emailVeified: 'อีเมลของคุณได้รับการยืนยันแล้ว',
    loginNewPass: 'คุณสามารถเข้าสู่ระบบด้วยรหัสผ่านใหม่ของคุณได้แล้ว',
    passwordchanged: 'รหัสผ่านถูกเปลี่ยนเรียบร้อยแล้ว',
    tryresetpassword: 'กรุณารีเซ็ตรหัสผ่านอีกครั้ง',
    checkEmail: 'ระบบได้ทำการส่งอีเมลเรียบร้อยแล้ว กรุณาตรวจสอบอีเมลของท่าน',
    for: 'สำหรับ',
    requirenewpass: 'ขอรหัสผ่านใหม่',
    tokenExpire: 'โทเค็นของคุณหมดอายุ',
    plsLoginAgain: 'กรุณาเข้าสู่ระบบอีกครั้งเพื่อแก้ไขข้อมูล <br> คุณต้องการเข้าสู่ระบบใหม่อีกครั้งหรือไม่',
    requestQR: 'หากคุณไม่ได้รับรหัสยืนยัน สามารถรับรหัสยืนยันได้ที่นี่',
    passwordNotCorrect: 'รหัสของคุณไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง',
    addressDesc: 'ที่อยู่ร้านสำหรับจัดส่งเดลิเวอรี่และสั่งอาหารผ่าน QR-Order',
    adminToolDesc: 'POS สามารถใช้งานได้เพียง 1 อุปกรณ์ (หรือตามจำนวนที่แพ็คเกจของคุณรองรับ) ต่อบัญชีเท่านั้น หากต้องการดูการทำงานเรียลไทม์ กรุณาใช้แอพลิเคชั่น Dashboard และหากต้องการรับออเดอร์ กรุณาใช้แอพลิเคชั่น Staff Order',
    loginToPOS: 'เข้าสู่ระบบ Silom POS',
    loginDes: 'กรุณากรอกแบบฟอร์มเพื่อเข้าสู่ระบบ Silompos',
    help: 'ช่วยเหลือ',
    transferwarn: 'การโอนสินค้าระหว่างสาขาต้องเป็นสาขาที่สร้างจากอีเมลเดียวกันเท่านั้น ไม่สามารถใช้ร่วมกับสาขาที่ใช้ต่างอีเมล',
    carousel: {
        slide1: {
            title: "ทดลองใช้ QR-ORDER ฟรี",
            buttonLabel: "ติดต่อพนักงาน"
        },
        slide2: {
            title: "เข้าร่วมกิจกรรมกับ Silom POS",
            buttonLabel: "เข้าร่วมกิจกรรม"
        },
        slide3: {
            title: "คุณสมัคร Line Alert หรือยัง?",
            buttonLabel: "เปิดใช้งาน"
        },
        slide4: {
            title: "ติดต่อเรา @SilomPOS",
            buttonLabel: "ติดต่อพนักงาน"
        },
        slide5: {
            title: "Big Menu เมนูใหญ่โดนใจ",
            buttonLabel: ""
        },
        slide6: {
            title: "เชื่อมต่อ Grab เพิ่มยอดขายง่ายๆ!",
            buttonLabel: ""
        },
        slide7: {
            title: "โปรโมชันจัดเต็ม เพิ่มยอดขาย!",
            buttonLabel: ""
        },
        slideLineNotify: {
            title: "ประกาศจาก Silom POS",
            buttonLabel: ""
        },
        slideLineNotifyAds: {
            title: "รับการแจ้งเตือนทุกการเคลื่อนไหว",
            buttonLabel: "เปิดใช้งาน"
        }
    },
    wrongCountryCode: 'รหัสประเทศไม่ถูกต้อง',
    dupTel: 'เบอร์โทรซ้ำกัน',
    contactAddress: 'ที่อยู่สำหรับติดต่อ',
    createNew: 'สร้างใหม่',
    updateMember: 'อัปเดตข้อมูล',
    saveAction: 'บันทึกใหม่',
    saveUpdate: 'อัปเดตและบันทึกใหม่',
    saveAs: 'บันทึกเป็น',
    importMember: 'นำเข้ารายชื่อสมาชิก',
    confirmImportMemberHeader: 'บันทึกรายชื่อสมาชิก',
    alertImportMemberDialog: 'รายชื่อสมาชิกที่ตรวจสอบไม่ผ่านจะไม่ถูกบันทึก',
    alertImportMemberDialog2: 'ข้อมูลที่จะถูกอัปเดตเป็นข้อมูลล่าสุดที่ทำการบันทึก<br>และข้อมูลคะแนนจะปรับยอดเป็นคะแนนล่าสุดที่มีการนำเข้า',
    confirmImportMember: 'คุณต้องการบันทึกรายชื่อสมาชิกใช่หรือไม่',
    saveMemberSuccessDialog: 'นำเข้ารายชื่อสมาชิกสำเร็จ',
    saveMemberSuccessDialog2: 'กรุณาตรวจสอบข้อมูลที่บันทึก',
    importMemberWarning: 'มีการนำเข้ารายชื่อสมาชิกอยู่',
    importMemberWarningDialog: 'ขณะนี้การนำเข้ารายชื่อสมาชิกก่อนหน้ายังไม่เสร็จสิ้น',
    importMemberPleaseWait: 'กรุณารอ 5 นาทีแล้วจึงนำเข้ารายชื่อสมาชิกใหม่อีกครั้ง',
    nameMemberRequired: "กรุณากรอกชื่อ",
    phoneRequired: "กรุณากรอกเบอร์โทรศัพท์",
    invalidPhone: "เบอร์โทรศัพท์ไม่ถูกต้อง",
    phoneExistsUpdate: "เบอร์มีแล้ว ข้อมูลจะเป็นการอัปเดต",
    validationFailed: "การตรวจสอบล้มเหลว",
    totalOfImportMembers: 'นำเข้ารายชื่อสมาชิก',
    countryCode: 'รหัสประเทศ',
    onhandQtyBalanceLessthan0: 'จำนวนคงคลังเหลือน้อยกว่า 0',
    onhandNow: 'ยอดคลังคงเหลือ',
    store: 'หน้าร้าน',
    online: 'ออนไลน์',
    stockOutGuideTitle: "คู่มือสำหรับสร้างเอกสารจ่ายสินค้าออก",
    stockOutMethodsIntro: "เอกสารจ่ายสินค้าออกเป็นการทำเอกสารจ่ายสินค้าออกเพื่อลดสต็อกสินค้าในระบบให้ตรงกับคลังสินค้า",
    stockOutStepsTitle: "ขั้นตอนการสร้างเอกสารจ่ายสินค้าออก",
    stockOutStep1: "กดปุ่มสร้างเอกสารจ่ายสินค้าออก",
    stockOutStep2: "กดปุ่มเพิ่มสินค้าหรือนำเข้าเอกสารจ่ายสินค้าออก",
    stockOutStep3: "กรอกข้อมูลที่จำเป็นเช่น จำนวน ราคา ",
    stockOutStep4: "กดปุ่มบันทึกเอกสาร",
    stockInGuideTitle: "คู่มือสำหรับสร้างเอกสารรับสินค้าเข้า",
    stockInMethodsIntro: "เอกสารรับสินค้าเข้าเป็นการทำเอกสารรับสินค้าเข้าเพื่อเพิ่มสต็อกสินค้าในระบบให้ตรงกับคลังสินค้า",
    stockInStepsTitle: "ขั้นตอนการสร้างเอกสารรับสินค้าเข้า",
    stockInStep1: "กดปุ่มสร้างเอกสารรับสินค้าเข้า",
    stockInStep2: "กดปุ่มเพิ่มสินค้าหรือนำเข้าเอกสารรับสินค้าเข้า",
    stockInStep3: "กรอกข้อมูลที่จำเป็นเช่น จำนวน ราคา ",
    stockInStep4: "กดปุ่มบันทึกเอกสาร",
    checkStockGuideTitle: "คู่มือสำหรับสร้างเอกสารตรวจนับ",
    checkStockMethodsIntro: "เอกสารตรวจนับเป็นการทำเอกสารนับสต๊อกสินค้าเพื่อจะนำไปใช้ต่อในเอกสารรับเข้า เอกสารจ่ายออกและเอกสารปรับปรุง",
    checkStockStepsTitle: "ขั้นตอนการสร้างเอกสารตรวจนับ",
    checkStockStep1: "กดปุ่มสร้างเอกสาร",
    checkStockStep2: "ใส่ชื่อเอกสารในช่องที่กำหนด",
    checkStockStep3: "กดปุ่มเพิ่มสินค้าและเลือกสินค้าที่ต้องการตรวจนับ",
    checkStockStep4: "กรอกจำนวนสินค้าในช่องตรวจนับ(สามารถแก้ไขได้ภายหลังเมื่อนำไปสร้างเอกสารรับเข้า-จ่ายออก)",
    checkStockStep5: "กดปุ่มยืนยันเพื่อบันทึกเอกสาร",
    adjustGuideTitle: "คู่มือสำหรับสร้างเอกสารปรับปรุงสต๊อก",
    adjustMethodsIntro: "เอกสารปรับปรุงสต๊อกเป็นการปรับยอดคลังสินค้ารายชิ้นเพื่อให้สินค้าในคลังและในระบบมีจำนวนเท่ากัน",
    adjustStepsTitle: "ขั้นตอนการสร้างเอกสารปรับปรุงสต็อก",
    adjustStep1: "กดปุ่มปรับปรุงสต๊อก",
    adjustStep2: "กดปุ่มเพิ่มสินค้าหรือนำเข้าจากเอกสารตรวจนับ",
    adjustStep3: "กรอกยอดสต๊อกของสินค้าแต่ละชิ้นแล้วกดยืนยัน",
    setMenu: 'วิธีการตั้งค่าเมนู',
    exportInvoice: 'วิธีการออกใบกำกับภาษี',
    moreVideo: 'เข้าสู่เว็บไซต์ Support',
    videofor: 'วิดีโอการใช้งาน',
    searchPlaces: "ค้นหาสถานที่",
    goToShopSetting: 'ไปที่การตั้งค่า',
    storeLocationDespt: "ร้านค้าต้องปักหมุดตำแหน่งร้านค้าก่อนเปิดใช้",
    customerLocationAccessDespt: "มือถือลูกค้าจำเป็นต้องอนุญาติการเข้าถึงตำแหน่งที่ตั้ง",
    customerRadiusDespt: "ลูกค้าต้องอยู่ในรัศมีร้านค้า ไม่เกิน 100 เมตร",
    staffOpenTableDespt: "พนักงานต้องกดเปิดโต๊ะก่อนให้ลูกค้าสแกนเข้าระบบ QR Order ",
    suggest: 'แนะนำ',
    enterPinDespt: "ลูกค้าต้องกรอกรหัสผ่าน 4 หลัก จากพนักงานเพื่อเข้าระบบ QR Order ",
    bestSecure: 'ดีเยี่ยม',
    goodSecure: 'สูง',
    moderateSecure: 'ปานกลาง',
    noSecure: 'ไม่ปลอดภัย',
    securityLevel: 'ระดับความปลอดภัย',
    dynamicQR: 'ปริ้นใหม่',
    staticQR: 'ติดที่โต๊ะ',
    dynamicQRDespt: 'พิมพ์ใหม่ทุกครั้งที่เปิดโต๊ะ',
    staticQRDespt: 'พิมพ์ครั้งเดียวติดไว้ที่โต๊ะ',
    qrType: 'รูปแบบ QR สั่งอาหาร',
    setSecurityOfQR: 'กำหนดระดับความปลอดภัยของ QR สั่งอาหาร',
    setSecurityOfQRDespt: 'เพื่อป้องกันการสั่งอาหารจากบุคคลอื่นที่อยู่ภายนอกร้าน <br> กรุณากำหนดเงื่อนไขการเข้าระบบ QR Order',
    noLocationPinned: 'ยังไม่ได้ปักหมุดที่อยู่',
    pinShopLocation: 'ปักหมุดที่อยู่ร้าน',
    changeOpenClose: 'หากแก้ไขเวลาเปิด-ปิดร้าน จะส่งผลต่อการนับบิลในรายงานยอดขายรายวัน กรุณาแก้ไขก่อนขายบิลแรกของวันที่แก้ไข',
    plsLoginShopee: 'กรุณาเข้าสู่ระบบ shopee อีกครั้งเพื่อยืนยันตัวตน',
    selectProductDesc: 'สินค้าจะถูกดึงข้อมูลจากมาร์เก็ตเพลส หากพบว่าสินค้าไม่มีอยู่ในระบบ จะมีการเพิ่มสินค้าใหม่เข้าระบบ แต่หากสินค้าดังกล่าวมีอยู่แล้ว ระบบจะทำการอัปเดตข้อมูลของสินค้านั้นให้เป็นปัจจุบัน',
    pullSuccess: 'บันทึกใบเสร็จเรียบร้อย',
    shopeeDesc1: 'หลังจากแก้ไขข้อมูลต้องซิงค์สินค้าลงมาจากมาร์เก็ตเพลสอีกครั้งข้อมูลที่แก้ไขถึงจะมีการเปลี่ยนแปลง',
    shopeeDesc2: 'การแก้ไขข้อมูลจะมีผลเฉพาะสินค้าตัวแรกในตัวเลือก หากต้องการแก้ไขสินค้าตัวเลือกอื่น ต้องแก้ไขในขนาดบรรจุอื่นๆ',
    descinformation: 'คำอธิบายเพิ่มเติม',
    thailand: 'ประเทศไทย',
    existsBill: 'มีบิลในระบบแล้ว',
    noExistsBill: 'ยังไม่มีบิลในระบบ',
    unavailable: 'ไม่พร้อมขาย',
    incancel: 'ขอยกเลิก',
    complete: 'สำเร็จ',
    invoicePending: 'รอใบชำระหนี้',
    authorize: 'ลงทะเบียน',
    confirmSaveProductTitle: 'ยืนยันการดึงข้อมูล',
    confirmPullProduct: 'คุณต้องการดึงข้อมูลจากมาร์เก็ตเพลสใช่หรือไม่',
    plsLoginShopee: 'กรุณาเข้าสู่ระบบมาร์เก็ตเพลสอีกครั้งเพื่อดึงข้อมูล',
    inventoryQuantity: 'จำนวนสินค้าคงคลัง',
    notFoundProduct: 'ไม่พบสินค้าในร้านค้า กรุณาเพิ่มข้อมูลสินค้าจากมาร์เก็ตเพลส',
    readyToShip: 'เตรียมสินค้า',
    payed: 'ชำระแล้ว',
    cancelled: 'ยกเลิก',
    shipping: 'กำลังจัดส่ง',
    payWaiting: 'รอชำระ',
    shipped: 'จัดส่งแล้ว',
    itemTotal: 'ยอดรวมราคาสินค้า',
    printReceiptToPOS: 'บันทึกใบเสร็จ',
    morethan15days: 'กรุณาเลือกย้อนหลังไม่เกิน 15 วัน',
    syncSuccess: 'เชื่อมต่อสำเร็จ',
    pleaseChooseMoreTimeRange: 'กรุณาเลือกวันเริ่มต้นและวันสิ้นสุดให้ต่างกัน',
    systemError: 'ขณะนี้ระบบขัดข้อง กรุณาลองใหม่ภายหลัง',
    shopCoverShipping: 'ร้านออกค่าจัดส่ง',
    pullproductFail: 'ดึงข้อมูลไม่สำเร็จ',
    pullFailDesc: 'ข้อมูลไม่สามารถเข้าสู่ระบบได้ กรุณาเชื่อมต่อมาร์เก็ตเพลสใหม่',
    exists: 'มีสินค้าในระบบแล้ว',
    noExists: 'ยังไม่มีสินค้าในระบบ',
    currency: 'สกุลเงิน',
    connectDetail: 'รายละเอียดการเชื่อมต่อ',
    addConnect: 'เพิ่มการเชื่อมต่อ',
    shopeeDesc: 'การเชื่อมต่อกับ Shopee ช่วยให้การจัดการธุรกิจออนไลน์เป็นเรื่องง่ายและมีประสิทธิภาพมากยิ่งขึ้น โดยคุณสามารถซิงค์ข้อมูลสินค้า คำสั่งซื้อ และสต็อกได้แบบเรียลไทม์ ลดการทำงานซ้ำซ้อน ประหยัดเวลา เพิ่มความถูกต้องแม่นยำ และช่วยขยายตลาดเพื่อเข้าถึงลูกค้าจำนวนมากบนแพลตฟอร์ม Shopee',
    selectProduct: 'เลือกสินค้าจากมาร์เก็ตเพลส',
    eComSetting: 'การตั้งค่ามาร์เก็ตเพลส',
    saveProductWarn: 'สินค้าจะถูกอัพเดตบนเว็บอีคอมเมิร์ซด้วย',
    used: 'ของมือสอง',
    otherDes: 'ฉันจะจัดส่งสินค้าภายใน 2 วัน โดยนับวันยืนยันคำสั่งซื้อเป็นวันที่ 0 (ไม่รวมวันหยุดนักขัตฤกษ์และวันหยุดทำการของบริษัทขนส่ง)',
    yes: 'ใช่',
    no: 'ไม่',
    Condition: 'สภาพ',
    preOrder: 'เตรียมส่งมากกว่าปกติ',
    others: 'อื่นๆ',
    maximumPurchase: 'จำนวนสูงสุดที่สั่งซื้อได้',
    minimumPurchase: 'จำนวนขั้นต่ำในการสั่งซื้อ',
    specialtyDiet: 'อาหารพิเศษ',
    volume: 'ปริมาณ',
    packageSize: 'ขนาดบรรจุ',
    expiryDate: 'วันหมดอายุ',
    licenseNum: 'หมายเลขใบอนุญาต/อย.',
    shelfLife: 'อายุการเก็บรักษา',
    ingredient: 'ส่วนประกอบ',
    countryOrigin: 'ประเทศต้นกำเนิด',
    brand: 'แบรนด์',
    fillProductDetail: 'โปรดกรอกรายละเอียดสินค้า',
    shippingWarn: 'การตั้งค่าการจัดส่งจะมีผลกับสินค้านี้เท่านั้น ค่าธรรมเนียมการจัดส่งที่แสดงจะเป็นอัตราค่าจัดส่งมาตรฐาน ซึ่งอาจมีการเปลี่ยนแปลงขึ้นอยู่กับสถานที่ตั้งของผู้ซื้อและผู้ขาย',
    shippingFee: 'ค่าจัดส่ง',
    parcelSize: 'ขนาดพัสดุ',
    weight: 'น้ำหนัก',
    shipping: 'การจัดส่ง',
    salesInfo: 'ข้อมูลการขาย',
    specification: 'คุณลักษณะของสินค้า',
    ecomProduct: 'การจัดการสินค้ามาร์เก็ตเพลส',
    pleaseCheckProductSync: 'กรุณาตรวจสอบสินค้าหลังการอัพเดตข้อมูล',
    connectEcomDesc: 'ระบบจะนำเข้าข้อมูลสินค้าละลูกค้าทั้งหมดจากช่องทางนี้อัตโนมัติหลังจากเชื่อมต่อสำเร็จแล้วข้อมูลสำคัญอื่น ๆ เช่น การชำระเงิน และการจัดส่ง จะถูกนำเข้าด้วยเฉพาะสำหรับเหตุการณ์ที่เกิดขึ้นหลังจากเชื่อมต่อกับร้านค้าเสร็จแล้วเท่านั้น',
    syncInfo: 'ตั้งค่าการซิงค์ข้อมูล',
    authorizeExpire: 'สิ้นสุดการเชื่่อมต่อ',
    country: 'ประเทศ/ภูมิภาค',
    authorizeBy: 'เชื่อมต่อโดย',
    storeID: 'ID ของร้านค้า',
    storeInfo: 'ข้อมูลร้านค้า',
    authorizeDate: 'วันที่เชื่อมต่อ',
    storeName: 'ชื่อร้านค้า',
    sync: 'ซิงค์ข้อมูล',
    connect: 'เชื่อมต่อ',
    connectMarketPlaces: 'เชื่อมต่อบัญชีมาร์เก็ตเพลสของคุณ',
    myincomedetails: 'รายละเอียดรายรับของฉัน',
    orderamendments: 'รายการปรับปรุงในคำสั่งซื้อ',
    incomefromorder: 'รายรับจากค่าสั่งซื้อ',
    feePayment: 'ค่าธรรมเนียมการชำระเงิน',
    totalCommission: 'ค่าคอมมิชชั่น',
    shippingByBuyer: 'ค่าจัดส่งที่ชำระโดยผู้ซื้อ',
    shippingByOwner: 'ค่าจัดส่งที่ชำระโดยผู้ให้บริการ',
    feesandcharges: 'ค่าธรรมเนียมและค่าบริการ',
    promotionForCreditCard: 'โปรโมชันสำหรับบัตรเครดิต',
    totalShippingPrice: 'ค่าจัดส่ง',
    totalPricePayMent: 'มูลค่าที่ต้องชำระ',
    paymentDetailTxt: 'รายละเอียดการชำระเงิน',
    purchaseOrderDetail: 'รายละเอียดการสั่งซื้อ',
    shippingAddressTxt: 'ที่อยู่ในการจัดส่ง',
    nameforShipping: 'ชื่อในการจัดส่ง',
    telShippig: 'เบอร์ติดต่อ',
    shippingDetailTxt: 'ข้อมูลการขนส่ง',
    printShippingLabel: 'พิมพ์ใบปะหน้าพัสดุ',
    deliveryDriver: 'พนักงานจัดส่ง',
    lastUpdated: 'อัปเดตเมื่อ',
    OrderListTXT: 'รายการสั่งซื้อสินค้า',
    ProductListMenu: 'รายการสินค้า',
    OrderListMenu: 'รายการสั่งซื้อ',
    completeTime: 'เวลาคำสั่งซื้อสำเร็จ',
    shippingTime: 'เวลาจัดส่ง',
    paymentTime: 'เวลาชำระเงิน',
    orderTime: 'เวลาสั่งซื้อ',
    addressShipping: 'ที่อยู่สำหรับจัดส่ง',
    customerAccount: 'ชื่อบัญชีผู้สั่ง',
    orderListtxt: 'รายการสั่งซื้อสินค้า',
    EcommerceMenu: 'การเชื่อมต่อมาร์เก็ตเพลส',
    EcommerceMenuTag: 'การเชื่อมต่อ',
    priceMustBeGreaterThanZero: "ราคาต้องไม่น้อยกว่า 0",
    importRemark: 'หลังจากนำเข้ารายการสินค้าในระบบแล้ว ท่านจะไม่สามารถแก้ไขหรือนำเข้ารายการสินค้าใหม่ได้ เนื่องจากบาร์โค้ดซ้ำ หากต้องการแก้ไขข้อมูลสินค้า เช่น ชื่อ หรือ ราคา ท่านสามารถดำเนินการได้ที่เครื่อง POS หรือเมนูสินค้า แต่หากท่านต้องการนำเข้ารายการสินค้าใหม่ จะต้องลบสินค้าที่ซ้ำออกก่อน',
    inventoryQuantity: 'จำนวนสินค้าคงเหลือ',
    totalCostValue: 'มูลค่าต้นทุนรวม',
    totalSalesValue: 'มูลค่าสินค้าคงเหลือ',
    DestinationdonthaveItem: 'สาขาปลายทางไม่มีสินค้าที่เลือก',
    allItems: 'รายการทั้งหมด',
    taxInvoiceBill: 'ใบกำกับภาษีรูปแบบเต็ม',
    printTaxInvoiceBill: 'พิมพ์ใบกำกับภาษีรูปแบบเต็ม',
    printshortTaxInvoiceBill: 'พิมพ์ใบกำกับภาษีอย่างย่อ',
    totalCost: 'ต้นทุนรวม',
    searchMembersale: 'กรุณากรอกชื่อลูกค้า',
    searchtelsale: 'กรุณากรอกชื่อเบอร์โทรศัพท์',
    totalITemVat: 'ยอดรวมเฉพาะสินค้ามีภาษี',
    vat: 'มูลค่าภาษี',
    netTotalIncVATDiscount: 'มูลค่าสินค้ามีภาษี (หลังหักส่วนลด)',
    netTotalBeforeVat: 'รวมมูลค่าก่อนภาษี',
    isnotVatIncluded: 'สินค้ายังไม่รวมภาษี',
    vatType: 'ประเภทภาษี',
    promotionPrice: 'ราคาโปรโมชัน',
    memberPrice: 'ราคาสมาชิก',
    taxBillSalesMenu: 'สรุปการขายเฉพาะสินค้ามีภาษี',
    taxBillSalesTXT: 'รายงานสรุปการขายสินค้าตามวัน(เฉพาะสินค้าภาษี)',
    optionSalesMenu: 'กลุ่มตัวเลือก',
    optionSalesByDateMenu: 'การสั่งกลุ่มตัวเลือกตามวัน',
    optionSalesByCostMenu: 'ต้นทุนตามตัวเลือก',
    optionSalesReportTXT: 'รายงานกลุ่มตัวเลือก',
    optionSalesByDateReportTXT: 'รายงานกลุ่มตัวเลือกตามวัน',
    optionSalesByCostReportTXT: 'รายงานต้นทุนตามตัวเลือก',
    orderStatusReportMenu: 'สถานะการสั่งสินค้า',
    orderStatusReportTXT: 'รายงานสถานะการสั่งสินค้า',
    billStatus: 'สถานะบิล',
    phoneReportMenu: 'ยอดใช้จ่ายตามลูกค้า',
    phoneReportTXT: 'รายงานยอดใช้จ่ายตามลูกค้า',
    orderType: 'ประเภทการสั่ง',
    dineIn: 'ทานที่ร้าน',
    takeAway: 'กลับบ้าน',
    agreed: 'รับทราบ',
    exampleCover: 'ตัวอย่างการแสดงภาพปกร้านค้า',
    coverDespt: 'แสดงเป็นภาพพื้นหลังของโลโก้ในหน้าเริ่มต้นเมื่อลูกค้าแสกนเข้าระบบ QR Order',
    basket: 'ตะกร้า',
    searchByName: 'ค้นหาโดยชื่อ...',
    viewAllFood: 'ทั้งหมด',
    foodCategory: 'หมวดหมู่อาหาร',
    tableEx: 'โต๊ะหมายเลข 7',
    themeSuggest: 'สีพื้นหลังที่กำหนดควรใช้สีเข้มเพื่อไม่ให้กลืนไปกับตัวหนังสือสีขาว',
    selfCustom: 'กำหนดเอง',
    shopThemeColor: 'ธีมสีของร้าน',
    gridSuggest: 'เหมาะสำหรับร้านที่ต้องการเน้นรูปอาหาร',
    listSuggest: 'เหมาะสำหรับร้านที่มีรายการอาหารเยอะ',
    gridLayout: 'ตาราง',
    listLayout: 'รายการ',
    menuLayout: 'รูปแบบเมนู',
    exTermOfService: 'Ex. ทานไม่หมดปรับขีดละ 20 บาท',
    showOnFirstLogin: 'แสดงเมื่อมีการเข้าหน้าสั่งอาหารครั้งแรก',
    termOfService: 'เงื่อนไขการใช้บริการ',
    setUpQR: 'ปรับแต่งหน้า QR Order',
    setUpKiosk: 'ปรับแต่งหน้า Kiosk',
    nonCost: 'สินค้าไม่มีต้นทุน',
    ratioCount: 'จำนวนที่ทำได้',
    quantityCount: 'ตัดสต็อก',
    stdCostWarning: 'ต้นทุนสูงกว่าราคาขาย',
    transferMenu: 'การโอนสินค้า',
    dueDate: 'วันที่กำหนดรับ',
    userManual: "คู่มือการใช้งาน",
    transferOutGuideTitle: "คู่มือสำหรับสร้างเอกสารโอนสินค้าออก",
    transferOutMethodsIntro: "การโอนสินค้าระหว่างสาขาสามารถทำได้ทั้งหมด 2 วิธี คือ แบบรออนุมัติหรือโอนย้ายทันที โดยมีขั้นตอนดังนี้",
    transferOutStepsTitle: "ขั้นตอนการสร้างเอกสารโอนสินค้าออก",
    transferOutStep1: "กดปุ่ม สร้างใบส่งออกสินค้า (TO)",
    transferOutStep2: "เลือกสาขาปลายทาง",
    transferOutStep3: "กำหนดวันครบกำหนด ที่สามารถให้สาขาปลายทางรับสินค้าได้",
    transferOutStep4: "เลือกสินค้าที่ต้องการส่งออก ไปยังสาขาปลายทาง (หากสาขาปลายทางไม่มีสินค้าที่สาขาต้นทางต้องการโอน ระบบจะไม่สามารถสร้างเอกสารโอนออกได้)",
    transferOutStep5: "กรอกจำนวนสินค้าที่ต้องการโอน ไปยังสาขาปลายทาง หากต้องการใส่หมายเหตุสามารถกรอกได้ที่ช่องหมายเหตุด้านล่าง",
    transferOutStep6: "กดปุ่ม โอนย้าย หลังจากกรอกรายละเอียดเรียบร้อย",
    transferOutStep7: "เลือก รออนุมัติหรือโอนย้ายทันที",
    transferOutNoteTitle: "หมายเหตุ",
    transferOutNote1: "กรณีเลือกสร้างเอกสารโอนออกแบบรออนุมัติ: ผู้ใช้งานสามารถแก้ไขจำนวนสินค้าและหมายเหตุได้ตลอดเวลาจนกว่าสาขาปลายทางจะกดรับสินค้า แต่ไม่สามารถลบหรือเพิ่มรายการสินค้าที่ต้องการโอนได้",
    transferOutNote2: "กรณีเลือกสร้างเอกสารแบบโอนย้ายทันที: ระบบจะโอนย้ายสินค้าอัตโนมัติโดยที่สาขาปลายทางไม่ต้องกดรับสินค้า และผู้ใช้งานจะไม่สามารถแก้ไขจำนวนสินค้าหรือหมายเหตุได้ในภายหลัง",
    transferOutNote3: "ในกรณีที่สาขาปลายทางรับสินค้าไม่ครบตามจำนวนที่สาขาต้นทางโอนไป ระบบจะแสดงเป็นแถบความคืบหน้า (progress bar) สาขาต้นทางสามารถเข้ามาเช็คเอกสารเพื่อตรวจสอบว่าปลายทางรับสินค้าไปจำนวนเท่าไหร่ และสามารถกดรับสินค้าที่สาขาปลายทางไม่ได้กดรับคืนเข้าคลังได้",
    transferOutNote4: "ผู้ใช้งานสามารถดูรายงานรายละเอียดการส่งหรือรับสินค้าได้ที่เมนู สินค้าคงคลัง > การส่งหรือรับสินค้า เพื่อดูรายละเอียดรายการสินค้าที่โอนออกและรับเข้า",
    transferInHelpTitle: 'คู่มือสำหรับการรับสินค้าระหว่างสาขา',
    transferInHelpintro: "ขั้นตอนการรับสินค้าระหว่างสาขา มีดังนี้",
    transferInstep1: "กดเลือกเอกสารรับที่อยู่ในสถานะ รออนุมัติ (Pending) ทั้งที่เป็นสีเหลืองหรือสีฟ้า",
    transferInstep2: "กรอกจำนวนรับเข้าที่ต้องการ หากฝั่งรับกดรับไม่ครบตามจำนวนที่ฝั่งโอนส่งมา ระบบจะแจ้งเตือนไปยังฝั่งโอนให้กดรับคืนสินค้าที่ไม่ได้รับ",
    transferInstep3: "กดอนุมัติ",
    transferInstep4: "เมื่อกดอนุมัติรับสินค้าสำเร็จแล้ว สามารถตรวจสอบรายการสินค้าที่เข้าคลังได้ที่เมนู สินค้าคงคลัง > รายงานความเคลื่อนไหว",
    transferInnote: "กรุณาตรวจสอบความเคลื่อนไหวสินค้าทุกครั้ง เพื่อเช็คความถูกต้องของสต๊อกสินค้า",
    diffQuantity: 'รับสินค้าน้อยกว่าจำนวนโอน',
    refNo: 'เลขที่เอกสารอ้างอิง',
    receivedAt: 'วันที่รับ',
    overDueDate: 'รับเกินวันที่กำหนด',
    dueDate: 'วันครบกำหนด',
    transferReportTXT: 'รายงานรายละเอียดการโอนและรับสินค้า',
    transferReportMenu: 'การโอนและรับสินค้า',
    searchTransfer: 'ค้นหาเลขที่เอกสาร',
    allDoc: 'เอกสารทั้งหมด',
    successDoc: 'สำเร็จ',
    pendingDoc: 'รออนุมัติ',
    cancelDoc: 'ยกเลิก',
    approve: 'อนุมัติ',
    transferOutMenu: 'โอนสินค้าระหว่างสาขา',
    transferInMenu: 'รับสินค้าระหว่างสาขา',
    createTransferDoc: 'สร้างใบส่งออกสินค้า (TO)',
    transferOutNo: 'เลขที่เอกสารโอน (TO)',
    transferInNo: 'เลขที่เอกสารรับ (TI)',
    saveSuccess: 'บันทึกสำเร็จ',
    docSaveEditComplete: 'บันทึกเอกสารสำเร็จ โปรดตรวจสอบเอกสารอีกครั้ง',
    saveEdit: 'บันทึกการแก้ไข',
    saveDocInvalid: 'ไม่สามารถบันทึกเอกสารได้ กรุณาลองใหม่',
    ApproveDocDes: 'คุณต้องการอนุมัติเอกสารโอนย้ายทันทีหรือไม่',
    ProductNotEnought: 'สินค้าคงเหลือไม่เพียงพอ',
    pendingTransfer: 'รออนุมัติ',
    ApproveTransfer: 'โอนย้ายทันที',
    quantityReceived: 'จำนวนรับเข้า',
    TransferDocNotFound: 'ไม่พบเอกสารการโอนสินค้า',
    transferDocumentCreationFailed: "สร้างเอกสารการโอนคลังไม่สำเร็จ",
    destinationSiteProductNotFound: "ไม่พบสินค้าที่สาขาปลายทาง",
    invalidStatus: "สถานะการสร้างเอกสารไม่ถูกต้อง",
    siteNotFound: "ไม่พบสาขาปลายทาง",
    documentAlreadyClosed: "เอกสารถูกโอนสำเร็จแล้ว",
    transferToSameSite: "สินค้าถูกโอนไปยังสาขาเดียวกัน",
    transferqtyis0: "สินค้าสาขาปลายทางถูกลบหรือจำนวนเป็น 0",
    productTransferOut: 'รายการสินค้าโอนออก',
    transferOutdescrip: 'เพิ่มสินค้าลงรายการโอนออกและตั้งค่าสินค้าแต่ละชิ้น',
    pending: 'รออนุมัติ',
    transferInTxt: 'เอกสารรับเข้า',
    transferOutTxt: 'เอกสารโอนออก',
    transferIn: 'รับเข้า',
    transferOut: 'โอนออก',
    nonCost: 'สินค้าไม่มีต้นทุน',
    ratioCount: 'จำนวนที่ทำได้',
    quantityCount: 'ตัดสต็อก',
    stdCostWarning: 'ต้นทุนสูงกว่าราคาขาย',
    nightMode: 'โหมดกลางคืน',
    importWarning: 'มีการใช้งานการนำเข้ารายงานสินค้าอยู่',
    importWarningDialog: 'ขณะนี้การนำเข้ารายการสินค้าก่อนหน้ายังไม่เสร็จสิ้น',
    importPleaseWait: 'กรุณารอ 5 นาทีแล้วจึงนำเข้ารายการสินค้าใหม่อีกครั้ง',
    startScreenSaverWhen: 'เริ่มโหมดพักหน้าจอหลังจากไม่มีการใช้งานใน',
    videoKioskScreenSaving: 'กำหนดเป็นวีดีโอ',
    imageKioskScreenSaving: 'กำหนดเป็นภาพ',
    screenSaverConfig: 'กำหนดโหมดพักหน้าจอ',
    nightMode: 'โหมดกลางคืน',
    selfService: 'สั่งอาหารด้วยตัวเอง',
    productDetail: 'การจัดการสินค้า',
    staffTxt: 'พนักงาน',
    taxTxt: 'ภาษี',
    inventoryTxt: 'สินค้าคงคลัง',
    warehouseTxt: 'การจัดการสต็อกและคลัง',
    invenTransfer: 'การโอนและรับระหว่างสาขา',
    salesTxt: 'การขาย',
    done: 'เรียบร้อย',
    moreInfo: 'สามารถดูคำอธิบายเพิ่มเติมได้ที่ ',
    importProductTxt: 'นำเข้ารายการสินค้า',
    fail: 'ไม่ผ่าน',
    pass: 'ผ่าน',
    donotShow: 'ไม่ต้องแสดงข้อความนี้อีก',
    skuRequired: 'จำเป็นต้องมีข้อมูล SKU Code',
    barcodeRequired: 'จำเป็นต้องมีข้อมูลบาร์โค้ด',
    categoryRequired: 'จำเป็นต้องมีข้อมูลกลุ่มสินค้า',
    nameRequired: 'จำเป็นต้องมีข้อมูลชื่อสินค้า',
    priceRequired: 'จำเป็นต้องมีข้อมูลราคาสินค้า',
    costRequired: 'ราคาต้นทุนต้องเป็นตัวเลข',
    ratioRequired: 'จำนวนตัดสต็อกต้องเป็นตัวเลข',
    barcodedup: 'บาร์โค้ดซ้ำกัน',
    priceMustBeNumber: 'ราคาสินค้าต้องเป็นตัวเลข',
    barcodeExists: 'มีสินค้าบาร์โค้ดนี้แล้ว',
    skuInvalid: 'ข้อมูล SKU Code ไม่ถูกต้อง',
    barcodeInvalid: 'ข้อมูลบาร์โค้ดไม่ถูกต้อง',
    categoryInvalid: 'ข้อมูลกลุ่มสินค้าไม่ถูกต้อง',
    nameInvalid: 'ข้อมูลชื่อสินค้าไม่ถูกต้อง',
    priceInvalid: 'ข้อมูลราคาสินค้าไม่ถูกต้อง',
    costNotAlphabet: 'ข้อมูลราคาต้องไม่มีตัวอักษร',
    priceNotAlphabet: 'ข้อมูลราคาต้องไม่มีตัวอักษร',
    vatMustBeChar: 'ข้อมูล VAT ต้องมีตัวอักษรเดียว',
    serialNumberisDup: 'หมายเลขผลิตภัณฑ์ซ้ำกัน',
    importFile: 'นำเข้าไฟล์',
    downloadExampleFile: 'ดาวโหลดไฟล์ตัวอย่าง',
    pleaseMakeCopyToDownload: "กรุณาสร้างสำเนาเพื่อดาวน์โหลดไฟล์จาก Template Google Sheet",
    pleaseMakeCopyToDownload2: "และนำเข้าไฟล์ไม่เกินครั้งละ 1000 รายการ เพื่อความถูกต้องและแม่นยำของข้อมูล",
    pleaseMakeCopyToDownload3: 'ระบบจะนำเข้าเฉพาะสินค้าใหม่เท่านั้น ขณะนี้ยังไม่สามารถแก้ไขรายงานสินค้าเดิมได้ ',
    requirementFileCreationWarning: "ข้อควรระวังในการสร้างไฟล์",
    requirementFillMandatoryFields: "ต้องกรอกข้อมูลสินค้าคอลัมน์",
    must: 'จำเป็น',
    everyFields: 'ทุกช่อง',
    requirementSameProductDifferentSizeColor: "กรณีเป็นสินค้าชนิดเดียวกันแต่ต่างกันที่ขนาด สี ให้ใช้ SKU เดียวกัน",
    requirementProductTypeSV: "SV = สินค้าเซอร์วิส",
    requirementNumericValues: "ราคาสินค้า, ต้นทุน, Ratio ต้องเป็นตัวเลขเท่านั้น",
    requirementSync: 'ก่อนและหลังจากนำเข้ารายการสินค้าเรียบร้อยแล้ว กรุณาซิงค์ข้อมูลที่เครื่อง POS เพื่อให้ระบบอัปเดตข้อมูลล่าสุด',
    importdesc: '<img src="/img/icon/warning.png" width="24px" class="mr-2">ก่อนและหลังอัปโหลดไฟล์นำเข้าสินค้า <br><span class="text-danger">ต้องซิงค์ข้อมูลที่เครื่อง POS</span> ทุกครั้ง',
    importdesc2: 'กรณีมากกว่า 1 สาขา ต้องทำการซิงค์ที่เครื่อง POS ทุกสาขาก่อนและหลังอัปโหลดไฟล์',
    requirementDownloadCSV: "ดาวน์โหลดไฟล์เป็น CSV ก่อน Import บนเว็บ",
    selfService: 'สั่งอาหารด้วยตัวเอง',
    staffTxt: 'พนักงาน',
    taxTxt: 'ภาษี',
    inventoryTxt: 'สินค้าคงคลัง',
    salesTxt: 'การขาย',
    askForReceipt: 'หากต้องการใบเสร็จ กรุณาติดต่อพนักงาน',
    tableNumber: 'หมายเลขโต๊ะ',
    paymentSuccess: 'ชำระเงินสำเร็จ',
    period: 'ระยะเวลา',
    yearly: 'รายปี',
    monthly: 'รายเดือน',
    startScreenSaverWhen: 'เริ่มโหมดพักหน้าจอหลังจากไม่มีการใช้งานใน',
    videoKioskScreenSaving: 'กำหนดเป็นวีดีโอ',
    imageKioskScreenSaving: 'กำหนดเป็นภาพ',
    screenSaverConfig: 'กำหนดโหมดพักหน้าจอ',
    Kiosk: 'คีออส',
    unSelect: 'ไม่กำหนด',
    optionalChoice: 'ไม่บังคับ',
    orderHere: 'สั่งที่นี่',
    onBoardingPagePreview: 'ตัวอย่างหน้าแรก',
    menuPagePreview: 'ตัวอย่างหน้าเมนู',
    printReceipt: "พิมพ์ใบเสร็จ",
    paymentMethod: 'วิธีการชำระเงิน',
    receiptRenew: 'ใบเสร็จ',
    payToAmount: 'ยอดชำระ',
    haveNotPaidYet: 'ยังไม่ชำระเงิน',
    retryPayment: 'กลับไปทำรายการใหม่',
    QRCodeExpired: 'คิวอาร์โค้ดหมดอายุ',
    checkStatus: 'ตรวจสอบสถานะ',
    payWithIn: 'กรุณาชำระเงินภายใน',
    minutes: 'นาที',
    alertSelectPaymentMethod: 'โปรดเลือกวิธีการชำระเงิน',
    scanForPay: 'สแกนคิวอาร์สำหรับชำระเงิน',
    scanForPayDescription: 'คิวอาร์โค้ดพร้อมเพย์ เปิดแอปพลิเคชันธนาคารและทำการสแกนคิวอาร์โค้ด เพื่อการชำระเงิน',
    QRPayment: 'ชำระด้วยคิวอาร์โค้ด',
    QRPromptPay: 'คิวอาร์พร้อมเพย์',
    QRCredit: 'คิวอาร์บัตรเครดิต',
    selectPaymentedthod: 'เลือกวิธีการชำระเงิน',
    selectPaymentedthodDescription: 'เลือกวิธีการชำระเงินสำหรับต่ออายุแพ็กเกจ',
    inputTaxForm: 'กรอกข้อมูลเพื่อออกใบกำกับภาษี',
    exit: 'เสร็จสิ้น',
    transactionSuccess: 'ชำระเงินสำเร็จ',
    notRequired: 'ไม่บังคับ',
    next: 'ถัดไป',
    packagePurchaseDate: 'วันที่ชำระ',
    packageName: 'แพ็กเกจ',
    billingPackageCycle: 'รอบบิล',
    packageNo: 'รหัสแพ็กเกจ',
    pricePackage: 'ราคา',
    renewPackage: 'ต่ออายุแพ็กเกจ',
    packgeUsageHistory: 'แพ็กเกจปัจจุบัน',
    purchaseHistory: 'ประวัติการชำระเงิน',
    packageAndRenew: 'แพ็กเกจและการชำระเงิน',
    moreInFo: 'สามารถดูคำอธิบายเพิ่มเติมได้ที่ ',
    requirementUniqueBarcode: "คอลัมน์ Barcode",
    requirenotduplicate: 'ห้ามซ้ำกันทุกกรณี',
    requirementVAT: "VAT:",
    requirementVATWithTax: "V = สินค้ามีภาษี",
    requirementVATWithoutTax: "N = สินค้าไม่มีภาษี",
    requirementProductType: "ประเภทสินค้า:",
    requirementProductTypeGeneral: "P = สินค้าทั่วไป",
    requirementProductTypeBOM: "BOM = สินค้าประกอบ",
    requirementProductTypeSN: "SN = สินค้ามี Serial",
    contactus: 'ติดต่อพนักงาน',
    kioskAds: 'เพิ่มความสะดวกสบายให้กับลูกค้าของคุณด้วยเครื่อง Kiosk อัจฉริยะที่ใช้งานง่ายและทันสมัย',
    noPackage: 'ไม่พบข้อมูลการต่ออายุ',
    noPackageDescription: 'ติดต่อพนักงาน',
    addCoverImage: 'ภาพปกร้านค้า',
    addLogoDespt: 'ใส่โลโก้ หรือ รูปภาพสินค้าหลักที่ไม่มีสีพื้นหลัง',
    inventoryAftertransfer: 'ยอดคลังหลังรับโอน',
    onhandQtyBefore: 'ยอดคลังก่อนรับ',
    transfer: 'โอนย้าย',
    createUser: 'ชื่อผู้สร้างเอกสาร',
    addOption: 'เพิ่มตัวเลือก',
    editOption: 'แก้ไขตัวเลือก',
    fillOption: 'กรุณากรอกชื่อตัวเลือก',
    txtOption: 'ชื่อตัวเลือก',
    optionitem: 'ตัวเลือก',
    savedOption: 'บันทึกตัวเลือกเสริมแล้ว',
    addOptionGroup: 'เพิ่มกลุ่มตัวเลือก',
    editOptionGroup: 'แก้ไขกลุ่มตัวเลือก',
    fillOptionGroup: 'กรุณากรอกชื่อกลุ่มตัวเลือก',
    txtOptionGroup: 'ชื่อกลุ่มตัวเลือก',
    addSKUratio: 'เพิ่มสินค้าตัดสต็อก',
    maximum: 'จำนวนสูงสุด',
    checkStockDoc: 'ตรวจนับสินค้า',
    checkStockTXT: 'เอกสารตรวจนับสินค้า',
    selectCheckStockDoc: 'เลือกเอกสารตรวจนับสินค้า',
    importcheckStockDoc: 'นำเข้าจากเอกสารตรวจนับ',
    checkStockName: 'ชื่อเอกสารตรวจนับ',
    createCheckStock: 'สร้างเอกสารตรวจนับสินค้า',
    checkStockProduct: 'รายการตรวจนับสินค้า',
    validateCheckStock: 'กรุณากรอกชื่อเอกสารตรวจนับ',
    require: 'ต้องเลือกอย่างน้อยหนึ่ง',
    dialigDeleteGroup: 'ต้องการลบกลุ่มตัวเลือกหรือไม่',
    confirmDeletGroup: 'ยืนยันการลบกลุ่มตัวเลือก',
    dialigDeleteOption: 'ต้องการลบตัวเลือกหรือไม่',
    confirmDeletOption: 'ยืนยันการลบตัวเลือก',
    productDataSaving: 'กำลังบันทึกข้อมูลสินค้า',
    imageUploading: 'กำลังอัปโหลดรูปภาพ',
    videoUploading: 'กำลังอัปโหลดวีดีโอ',
    videoSizeNotTooBig: 'ขนาดวีดีโอไม่เกิน 20 MB',
    bigVideoAlert: 'วีดีโอนี้ขนาดใหญ่เกิน 20 MB',
    pasteURL: 'วางลิงก์ URL',
    deleteVideo: 'ลบวีดีโอ',
    uploadVideo: 'อัปโหลดวีดีโอ',
    activeAll: 'เปิดขายทั้งหมด',
    deactiveAll: 'ปิดขายทั้งหมด',
    inputURLPlaceholder: 'กรอกลิงก์ URL',
    confirmSaveQRConfigDialog: 'คุณต้องการบันทึกการตั้งค่าหน้าเมนู QR-ORDER ใช่หรือไม่',
    confirmSaveQRConfig: 'ยืนยันการบันทึกการตั้งค่าหน้าเมนู QR-ORDER',
    confirmSaveKioskConfigDialog: 'คุณต้องการบันทึกการตั้งค่าหน้าเมนูคีออสใช่หรือไม่',
    confirmSaveKioskConfig: 'ยืนยันการบันทึกการตั้งค่าหน้าเมนูคีออส',
    linkBannerTo: 'ลิงก์ภาพแบนเนอร์ไปที่',
    externalLink: 'URL',
    addBanner: 'รูปแบนเนอร์',
    addBannerSuggestion: 'แนะนำขนาดภาพสัดส่วน 2:1 หรือ 1280x640 พิกเซล',
    addBannerSuggestionKiosk: 'แนะนำขนาดภาพสัดส่วน 3:1 หรือ 2000x667 พิกเซล',
    onSale: 'เปิดขาย',
    shopGreetingTitle: 'คำกล่าวทักทาย',
    shopGreetingDescription: 'คำกล่าวทักทายรอง',
    shopGreetingDialog: 'Ex. ชื่อร้าน หรือ ข้อความทักทายลูกค้า',
    previewScreen: 'ตัวอย่าง',
    previewDialog: 'สัดส่วนขึ้นอยู่กับขนาดของมือถือลูกค้า',
    viewAll: 'ดูทั้งหมด',
    seemore: 'ดูเพิ่มเติม',
    recommendMenu: 'เมนูแนะนำ',
    qrMobileConfig: 'ปรับแต่งหน้าสั่งอาหารด้วยตัวเอง',
    qrMenuDetailsAdjustment: 'ปรับแต่งหน้าเมนู QR Order',
    useInQr: 'ภาพ/วิดีโอ ขนาด 500x500 px หรือ 1:1',
    txtStore: 'ขายหน้าร้าน',
    seemore: 'ดูเพิ่มเติม',
    additionalImage: 'รูปภาพ/วีดีโอเพิ่มเติม  <br>แนะนำสัดส่วน 1:1 เช่น 500x500 พิกเซล',
    inactiveStock: 'ยังไม่มีการเคลื่อนไหว',
    nonStock: 'ไม่มีสต๊อก',
    enterProvince: 'กรอกจังหวัด',
    enterAmphoe: 'กรอกเขต/อำเภอ',
    enterTambon: 'กรอกแขวง/ตำบล',
    enterAddress: 'กรอกที่อยู่',
    enterPostcode: 'กรอกรหัสไปรษณีย์',
    enterFirstname: 'กรอกชื่อ-นามสกุล',
    enterLastname: 'กรอกนามสกุล',
    enterCustomerName: 'กรอกชื่อลูกค้า',
    enterTaxIDNumber: 'กรอกเลขประจำตัวประชาชน/เลขนิติบุคคล',
    enterTel: 'กรอกเบอร์โทรศัพท์',
    enterBranchname: 'กรอกชื่อสาขา',
    enterEmail: 'กรอกอีเมล',
    customerInfo: 'ข้อมูลลูกค้า',
    optionTotal: 'มูลค่าตัวเลือก',
    chooseBirthDate: 'เลือกวันเกิด',
    reporttxt: 'รายงาน',
    general: 'ทั่วไป',
    shopname: 'ชื่อร้าน',
    branchName: 'สาขา',
    businessType: 'ประเภทธุรกิจ',
    taxID: 'เลขประจำตัวผู้เสียภาษี',
    nameTaxpayer: 'ชื่อ-สกุล ผู้เสียภาษีหรือชื่อบริษัท',
    txtTaxAddress: 'ที่อยู่สำหรับออกใบกำกับภาษี',
    vat: 'ภาษีมูลค่าเพิ่ม',
    exclusive: 'รวม VAT',
    inclusive: 'ไม่รวม VAT',
    serviceCharge: 'ค่าบริการ',
    tax: 'ภาษี',
    address: 'ที่อยู่',
    address1: 'ที่อยู่บรรทัดที่ 1',
    address2: 'ที่อยู่บรรทัดที่ 2',
    tel: 'เบอร์โทรศัพท์',
    shopopen: 'เวลาเปิด-ปิดร้าน',
    open: 'เปิด',
    closed: 'ปิด',
    languagetxt: 'ภาษา',
    language: 'ภาษาและโซนเวลา',
    languageTH: 'ไทย',
    languageEN: 'อังกฤษ',
    signOut: 'ออกจากระบบ',
    signIn: 'เข้าสู่ระบบ',
    or: 'หรือ',
    forgotPassword: 'ลืมรหัสผ่านใช่หรือไม่',
    setting: 'การตั้งค่า',
    shopsetting: 'การจัดการร้านค้า',
    shop: 'ร้านค้า',
    timezone: 'โซนเวลา',
    GMTminus12: '(GMT-12:00) เส้นวันที่ระหว่างประเทศตะวันตก',
    deliveryProviders: 'รายชื่อผู้ให้บริการเดลิเวอรี่',
    adddeliveryProviders: 'เพิ่มผู้ให้บริการ',
    lastUpdate: 'วันที่อัปเดต',
    logo: 'โลโก้',
    businessType1: 'ร้านค้าทั่วไป',
    businessType2: 'ร้านอาหาร',
    businessType3: 'โฮสเทล',
    updateData: 'อัปเดตข้อมูล',
    submit: 'ตกลง',
    cancel: 'ยกเลิก',
    normal: 'ปกติ',
    loading: 'กำลังโหลด...',
    verifying: 'กำลังยืนยัน...',
    editing: 'กำลังแก้ไข',
    confirmUpdate: 'คุณต้องการแก้ไขข้อมูลหรือไม่',
    selectedShoptype: 'เลือกประเภทร้าน...',
    selectshop: 'เลือกสาขา...',
    save: 'บันทึก',
    unit: 'หน่วยบรรจุ',
    category: 'กลุ่มสินค้า',
    product: 'สินค้า',
    export: 'ส่งออกไฟล์',
    exportKBank: 'ส่งออกไฟล์ K-Bank',
    date: 'วันที่',
    to: 'ถึง',
    status: 'สถานะ',
    vatAmount: 'คิดเป็นมูลค่าภาษี',
    netTotalNonVAT: 'สินค้าไม่มีภาษี',
    receiptNumber: 'เลขที่บิล',
    taxInvoiceReceipt: 'เลขที่ใบกำกับภาษี',
    grandTotal: 'ยอดขาย',
    netAmountVATTotal: 'มูลค่าสินค้าก่อน VAT',
    netTotalIncVAT: 'สินค้ามีภาษี',
    proofOfPayment: 'หลักฐานการชำระเงิน',
    paymentType: 'ประเภทการชำระ',
    paymentType0: 'เงินสด',
    paymentType1: 'บัตรเครดิต',
    paymentType2: 'คูปอง',
    paymentType3: 'Quickpay',
    paymentType4: 'โอน(พร้อมเพย์)',
    paymentType5: 'Alipay',
    paymentType6: 'มัดจำ',
    paymentType7: 'Delivery',
    paymentType8: 'Online',
    paymentType9: 'Line Pay',
    paymentType10: 'กำหนดเอง',
    paymentType12: 'KBank',
    paymentType14: 'EDC',
    otherPayment: 'อื่นๆ',
    paymentAmount: 'จำนวนเงิน',
    payment: 'การชำระเงิน',
    paid: 'มูลค่าการชำระ',
    change: 'เงินทอน',
    sentSuccessfully: 'ส่งแล้ว',
    pluCode: 'รหัสสินค้า',
    productName: 'ชื่อสินค้า',
    quantity: 'จำนวน',
    priceUnit: ' ราคาขาย/หน่วย',
    discount: 'ส่วนลด',
    netAmountVATTotal: 'มูลค่าก่อน VAT',
    transferQty: 'จำนวนโอน',
    transferReceived: 'จำนวนรับโอน',
    inventoryBalance: 'ยอดคลังหลังรับโอน',
    destinationQty: 'ยอดคลังสาขาปลายทาง',
    shopQty: 'ยอดคลังสาขาต้นทาง',
    tableNo: 'หมายเลขโต๊ะ',
    avgDuration: 'ระยะเวลาใช้โต๊ะเฉลี่ย (นาที)',
    avgGrandTotal: 'จำนวนเงินเฉลี่ย(บาท)',
    avgNumCust: 'ลูกค้าเฉลี่ย(คน)',
    avgItems: 'สินค้าเฉลี่ย(รายการ)',
    numCust: 'ลูกค้าทั้งหมด',
    timeOfOrder: 'เวลาที่สั่งซื้อ',
    orderDate: 'สั่งซื้อเมื่อ',
    preparing: 'เวลาเตรียมสินค้า',
    paymentDetail: 'รายละเอียดการขาย',
    orderNumber: 'หมายเลขคำสั่งซื้อ',
    delivered: 'กำหนดส่ง',
    deliveredDate: 'เวลาส่ง',
    baht: 'บาท',
    profit: 'กำไร',
    sales: 'ยอดขาย',
    billAmount: 'จำนวนบิล',
    tableAmount: 'สถิติการใช้โต๊ะ',
    total: 'รวมทั้งสิ้น',
    bill: 'บิล',
    discountAmount: 'ส่วนลดท้ายบิล',
    netAmount: 'รวมก่อนลด',
    averageBill: 'เฉลี่ย/บิล',
    voidbillTotal: 'มูลค่าการยกเลิก',
    salesByDaily: 'ยอดขายรายวัน',
    salesByMonth: 'ยอดขายรายเดือน',
    bestSellers: 'สินค้าขายดี',
    bestSellerstype: 'ประเภทสินค้าขายดี',
    salesBydate: 'ยอดขายแยกตามช่วงเวลา',
    number: 'ลำดับ',

    categoryName: 'ชื่อประเภท',
    newOrder: 'คำสั่งซื้อใหม่',
    reject: 'ปฏิเสธ',
    rejectTime: 'เวลาที่ปฏิเสธ',
    inProgressTime: 'เวลาเตรียมสินค้า',
    cancelTime: 'เวลาที่ยกเลิก',
    cause: 'เหตุผลที่ยกเลิก',
    preparingToShip: 'กำลังเตรียมสินค้า',
    totalAmount: 'ยอดสุทธิ',
    order: 'คำสั่งซื้อ',
    deposit: 'มัดจำ',
    billSave: 'พักบิล',
    ReceivingOrder: 'เปิดโต๊ะ/กำลังรับออเดอร์',
    orderNo: 'เลขที่รายการ',
    remark: 'หมายเหตุท้ายบิล',
    receiptStatus: 'สถานะ',
    back: 'กลับ',
    customerCate: 'กลุ่มลูกค้า',
    detail: 'รายละเอียด',
    countSales: 'ขายได้จำนวน',
    onhandQty: 'คงเหลือ',
    stdCost: 'ราคาทุน',
    employees: 'พนักงาน',
    employeesname: 'ชื่อพนักงาน',
    salesTotal: 'ยอดขายทั้งหมด',
    billActive: 'บิลที่ขาย',
    billVoice: 'บิลยกเลิก',
    billDeposit: 'บิลมัดจำ',
    discountItem: 'ส่วนลดในรายการ',
    billDiscountAmount: 'ส่วนลดท้ายบิล',
    billDiscount: 'บิลที่ทำส่วนลด',
    payTotal: 'ชำระรวม',
    deliveryProvider: 'ประเภท Delivery',
    deliveryDetail: 'รายละเอียดรายการ Delivery',
    customDetail: 'รายละเอียดรายการจ่ายเงินอื่นๆ',
    customPaymentName: 'ประเภทรายการ',
    firstInvoiceNO: 'เลขที่บิลเริ่มต้น',
    lastInvoiceNO: 'เลขที่บิลสุดท้าย',
    roundNo: 'รอบการขายที่',
    closeAt: 'เวลาปิดรอบการขาย',
    closeBy: 'ปิดรอบขายโดย',
    totalCashSales: 'ยอดขายด้วยเงินสด',
    initialChange: 'เงินทอนเริ่มต้น',
    totalCashIn: 'เงินเข้า',
    totalCashOut: 'เงินออก',
    actualInDrawer: 'จำนวนเงินที่นับได้ในลิ้นชัก',
    expectedInDrawer: 'จำนวนเงินที่ควรมีในลิ้นชัก',
    difference: 'ส่วนต่าง',
    voidBillDetail: 'รายละเอียดบิลยกเลิกการขาย',
    cancelBy: 'ยกเลิกโดย',
    cancelDate: 'ยกเลิกเวลา',
    searchByEmployees: 'ค้นหาพนักงาน',
    searchEmployees: 'ค้นหาพนักงาน',
    search: 'ค้นหา',
    timeIn: 'เวลาเข้างาน',
    timeOut: 'เวลาออกงาน',
    time: 'ชั่วโมงการทำงาน',
    hours: 'ชั่วโมง',
    seconds: 'นาที',
    netTotal: 'ยอดเงินสุทธิ',
    reason: 'หมายเหตุ',
    searchByProduct: 'ค้นหาจากชื่อสินค้า',
    sortBy: 'เรียงโดย',
    last: 'ล่าสุด',
    price: 'ราคาสินค้า',
    sortByNumMin: 'ราคาน้อยไปมาก',
    sortByNumMax: 'ราคามากไปน้อย',
    sortBySalesMin: 'ยอดขายน้อยไปมาก',
    sortBySalesMax: 'ยอดขายมากไปน้อย',
    sortByBillAmountMin: 'จำนวนบิลน้อยไปมาก',
    sortByBillAmountMax: 'จำนวนบิลมากไปน้อย',
    documentNo: 'เลขที่เอกสาร',
    transactionType: 'ประเภท',
    updatedBy: 'ปรับปรุงโดย',
    transactionType1: 'ขายออก',
    transactionType2: 'รับคืนจากลูกค้า',
    transactionType3: 'รับสินค้าเข้า',
    transactionType4: 'จ่ายสินค้าออก',
    transactionType5: 'ปรับปรุงเพิ่ม',
    transactionType6: 'ปรับปรุงลด',
    transactionType7: 'แลกด้วยแต้ม',
    transactionType8: 'ลบ-รับเข้า',
    transactionType9: 'ลบ-จ่ายออก',
    transactionType10: 'แก้ไข-รับเข้า',
    transactionType11: 'แก้ไข-จ่ายออก',
    transactionType12: 'ยอดยกมา',
    transactionType13: 'ส่วนประกอบ',
    transactionType14: 'ตัวเลือก',
    transactionType15: 'การโอนออก',
    transactionType16: 'การรับเข้า',
    transactionType17: 'ยกเลิกจากการโอน',
    transactionType18: 'โอนคืนจากเอกสารโอนคลัง',
    transactionType19: 'ยกเลิกสินค้าโอนคลัง',
    transactionOther: 'อื่นๆ',

    billNo: 'เลขที่ในบิลซื้อ',
    docTotal: 'ยอดรวม',
    customer: 'ผู้จำหน่าย',
    username: 'ชื่อผู้ใช้',
    dateOfBill: 'วันที่ซื้อในบิล',
    selectDate: 'เลือกวันที่...',
    dialogSelectCategory: 'กรุณาเลือกหมวดหมู่การค้นหา',
    dialogUpdateDocumentSuccess: 'แก้ไขเอกสารเรียบร้อยแล้ว',
    dialogfound: 'กรอกข้อมูลไม่ถูกต้อง',
    isVatIncluded: 'สินค้ารวมภาษีแล้ว',
    vatIccludes: 'คิดภาษีมูลค่าเพิ่ม',
    confirmStock: 'ยืนยันการซื้อ',
    confirm: 'ยืนยัน',
    deleteDoc: 'ลบเอกสาร',
    confirmUpdateDocModal: 'คุณต้องการบันทึกการแก้ไขเอกสารหรือไม่ ?',
    confirmSaveDoc: 'ยืนยันการบันทึกเอกสาร',
    confirmDeleteDoc: 'ยืนยันการลบเอกสาร',
    confirmDeleteModal: 'คุณต้องการลบเอกสารหรือไม่',
    confirmDeleteTransferModal: 'คุณต้องการลบเอกสารหรือไม่',
    dialogSaveDocumentSuccess: 'สร้างเอกสารรับสินค้าเข้าเรียบร้อยแล้ว',
    createStockIn: 'สร้างเอกสารรับสินค้าเข้า',
    confirmSaveDocModal: 'คุณต้องการบันทึกเอกสารรับเข้าหรือไม่ ?',
    confirmCheckStockMsg: 'คุณต้องการบันทึกเอกสารตรวจนับสต๊อกหรือไม่ ?',
    confirmAdjustStockMsg: 'คุณต้องการบันทึกเอกสารปรับปรุงสต๊อกหรือไม่ ?',
    selectShopPlease: 'กรุณาเลือกร้านค้า',
    selectProductPlease: 'กรุณาเลือกสินค้า',
    duplicateProduct: 'คุณเลือกรายการสินค้านี้แล้ว',
    productInvilid: 'รายการสินค้าที่คุณเลือกไม่ถูกต้อง กรุณาติดต่อผู้ดูแลระบบ',
    confirmStockOut: 'ยืนยันการจ่ายออก',
    dialogSaveDocumentOutSuccess: 'สร้างเอกสารจ่ายสินค้าออกเรียบร้อยแล้ว',
    confirmSaveDocStockOutModal: 'คุณต้องการบันทึกเอกสารจ่ายออกหรือไม่ ?',
    adjustDocType: 'ประเภทเอกสาร',
    adjustDocType1: 'ปรับปรุงเพิ่ม',
    adjustDocType2: 'ปรับปรุงลด',
    beforeAdjust: 'ก่อนปรับปรุง',
    afterAdjust: 'หลังปรับปรุง',
    checkCount: 'ยอดตรวจนับ',
    adjust: 'ปรับปรุง',
    createAdjustStock: 'สร้างเอกสารปรับปรุงสินค้า',
    confirmAdjust: 'ยืนยันการปรับปรุง',
    searhBy: 'ค้นหาสินค้าโดย',
    createDoucument: 'สร้างเอกสาร',
    transferType: 'ประเภทเอกสาร',
    transferType1: 'เอกสารรับเข้า',
    transferType2: 'เอกสารจ่ายออก',
    new: 'ใหม่',
    finish: 'สำเร็จ',
    refDocument: 'อ้างอิงจากเอกสาร',
    statusDoc: 'สถานะเอกสาร',
    dialogSaveTransferDocumentSuccess:
        'สร้างเอกสารจ่ายสินค้าระหว่างสาขาเรียบร้อยแล้ว',
    destinationShop: 'ร้านค้าปลายทาง',
    destinationShopBranch: 'สาขาปลายทาง',
    sourceBranch: 'สาขาต้นทาง',
    sourceShop: 'ร้านค้าต้นทาง',
    sourceAddress: 'ที่อยู่สาขาต้นทาง',
    destinationAddress: 'ที่อยู่สาขาปลายทาง',
    saveTransfer: 'บันทึกเอกสารการโอน',
    confirmSaveTransfer: 'คุณต้องการบันทึกการโอนสินค้าระหว่างสาขาหรือไม่',
    savingDoc: 'กำลังบันทึกเอกสาร',
    selectdestinationPlease: 'กรุณาเลือกสาขาปลายทาง',
    validatedestinationproduct: 'สาขาปลายทางไม่มีสินค้า',
    validateProduct: 'กรุณาเพิ่มสินค้า',
    created_by: 'โดย',
    receive: 'รับ',
    updateDoc: 'แก้ไขเอกสาร',
    cancelDoc: 'ยกเลิกเอกสาร',
    confirmCancelDoc: 'คุณต้องการยกเลิกเอกสารหรือไม่',
    cancelingDoc: 'กำลังยกเลิกเอกสาร',
    updatingDoc: 'กำลังแก้ไขเอกสาร',
    updateDocFail: 'ไม่สามารถแก้ไขเอกสารได้',
    cancelDocSuccess: 'ยกเลิกเอกสารสำเร็จ',
    cancelDocFail: 'ไม่สามารถยกเลิกเอกสารได้',
    refDocumentTransfer: 'เลขเอกสารอ้างอิงจากต้นทาง',
    comfrimTransferIn: 'ยืนยันการรับสินค้า',
    dilogcomfrimTransferIn: 'คุณต้องการยืนยันการรับสินค้าหรือไม่',
    transferInDetail: 'รายละเอียดสินค้ารับเข้า',
    totalNet: 'รวมมูลค่าสินค้า',
    createStockOut: 'สร้างเอกสารจ่ายสินค้าออก',
    favorite: 'สินค้า-นิยม',
    isOnScreen: 'แสดงบนหน้าขาย',
    negotiatePrice: 'ราคาด่วน', //
    productTypeP: 'สินค้าทั่วไป',
    productTypeBOM: 'สินค้าประกอบ (BOM)',
    productTypeSN: 'สินค้ามี Serial',
    productTypeSV: 'สินค้าบริการ (สินค้าไม่มีสต๊อก)',
    productPLU: 'ขนาดบรรจุอื่นๆ',
    productBOM: 'ส่วนประกอบ',
    productSN: 'หมายเลขผลิตภัณฑ์',
    morePrice: 'ราคาขายเพิ่มเติม',
    cost: 'ต้นทุน',
    quantityPrice: 'จำนวน/ราคาขาย',
    exceedUc: 'ราคาขาย',
    changeImg: 'เปลี่ยนรูปภาพ',
    addProduct: 'เพิ่มสินค้า',
    selectImg: 'เลือกรูปภาพ',
    deleteImg: 'ลบรูปภาพ',
    selectImgFromPC: 'เลือกรูปภาพจากเครื่อง',
    confirmSaveImgDialog: 'คุณต้องการบันทึกสินค้าหรือไม่',
    confirmSaveImg: 'ยืนยันการบันทึกสินค้า',
    clearTableDataDialog: 'คุณต้องการลบข้อมูลในตารางหรือไม่',
    clearTableData: 'ยืนยันการลบข้อมูลในตารางหรือไม่',
    addPrice: 'เพิ่มราคาขาย',
    editPrice: 'แก้ไขราคาขาย',
    dialigDeleteProduct: 'คุณต้องการลบสินค้าหรือไม่',
    confirmDeleteProduct: 'ยืนยันการลบสินค้า',
    productType: 'ประเภทสินค้า',
    countProduct: 'สินค้าทั้งหมด',
    addUnit: 'เพิ่มหน่วยบรรจุ',
    editUnit: 'แก้ไขหน่วยบรรจุ',
    active: 'ใช้งาน',
    inactive: 'ไม่ใช้งาน',
    confirmSaveUnitDialog: 'คุณต้องการบันทึกหน่วยสินค้าหรือไม่',
    confirmSaveUnit: 'ยืนยันการบันทึกหน่วยสินค้า',
    dialigDeleteUnit: 'คุณต้องการลบหน่วยของสินค้าหรือไม่',
    confirmDeletUnit: 'ยืนยันการลบหน่วยสินค้า',
    confirmSaveCategoryDialog: 'คุณต้องการบันทึกกลุ่มสินค้าหรือไม่',
    confirmSaveCategory: 'ยืนยันการบันทึกกลุ่มสินค้าา',
    dialigDeleteCategory: 'ยืนยันการลบกลุ่มสินค้า',
    confirmDeleteCategory: 'คุณต้องการลบกลุ่มสินค้าหรือไม่',
    savedCategory: 'บันทึกกลุ่มสินค้าเรียบร้อย',
    addCategory: 'เพิ่มกลุ่มสินค้า',
    editCategory: 'แก้ไขกลุ่มสินค้า',
    bgColor: 'สีพื้นหลัง',
    selectbgColor: 'เลือกสีพื้นหลัง',
    selectCategoryIcon: 'เลือกไอคอนหมวดหมู่',
    customCategoryIcon: 'ไอคอนหมวดหมู่',
    icon: 'ไอคอน',
    clear: 'ล้าง',
    validateCategoryname: 'กรุณากรอกชื่อกลุ่มสินค้า',
    pressEnterSelect: 'กดเพื่อเลือก',
    pressEnterRemove: 'กดเพื่อลบ',
    selected: 'เลือกแล้ว',
    sequence: 'ลำดับการแสดงข้อมูล',
    enterProduct: 'กรุณากรอกชื่อสินค้า',
    enterPrice: 'กรุณากรอกราคาสินค้า',
    selectCategory: 'กรุณาเลือกกลุ่มสินค้า',
    selectProductType: 'กรุณาเลือกประเภทสินค้า',
    selectUnit: 'กรุณาเลือกหน่วยสินค้า',
    confirmSaveOption: 'คุณต้องการตัวเลือกเสริมหรือไม่?',
    confirmSaveProduct: 'คุณต้องการบันทึกสินค้าหรือไม่?',
    confirmSaveChangeModal: 'หากลูกค้าต้องการแก้ไขส่วนประกอบ',
    confirmSaveChangeModal2: 'จะไม่สามารถยกเลิกบิลที่ทำการขายไปก่อนหน้านี้ได้ ',
    confirmSaveChangeModal3: 'เพราะอาจมีผลต่อสต๊อกสินค้า',
    confirmSaveChangeModal4: 'ท่านต้องการดำเนินการแก้ไขส่วนประกอบหรือไม่?',
    confirmDeleteProduct: 'คุณต้องการลบสินค้าหรือไม่',
    confirmSaveProductPrice: 'คุณต้องการบันทึกราคาสินค้าเพิ่มเติมหรือไม่',
    validatepackQty: 'กรุณากรอก จำนวน/ราคาขาย',
    validatepackUc: 'กรุณากรอก ราคาขาย',
    validatePRU: 'ข้อมูลราคาขายเพิ่มเติมนี้มีอยู่ กรุณากรอกใหม่อีกครั้ง',
    createProductSuccess: 'สร้างสินค้าเรียบร้อยแล้ว',
    updateProductSuccess: 'อัพเดตสินค้าเรียบร้อย',
    addSerial: 'เพิ่มหมายเลขผลิตภัณฑ์',
    add: 'เพิ่ม',
    createSerial: 'สร้าง Serial อัตโนมัติ',
    available: 'พร้อมจำหน่าย',
    soldOut: 'จำหน่ายแล้ว',
    deleteSuccess: 'ลบข้อมูลสำเร็จ ',
    enterSerial: 'กรุณากรอก Serial Number',
    confirmSaveSerial: 'คุณต้องการบันทึกหมายเลข Serial number หรือไม่?',
    confirmDelete: 'คุณต้องการลบ Serial number นี้หรือไม่?',
    addProductPLU: 'เพิ่มขนาดบรรจุอื่นๆ',
    img: 'รูปภาพ',
    SKURatio: 'ตัดสต็อก',
    editProductPLU: 'แก้ไขขนาดบรรจุ',
    enterSKURatio: 'กรุณากรอกสต็อกสินค้า',
    comfrimDeletePLU: 'คุณต้องลบขนาดบรรจุหรือไม่?',
    useStepPrice: 'ใช้ราคาขายเพิ่มเติมเมื่อถึงจำนวนที่กำหนดไว้',
    addBOM: 'เพิ่มส่วนประกอบ',
    confirmSaveBOM: 'คุณต้องการบันทึกหรือไม่?',
    user: 'บัญชีร้านค้า',
    userDetail: 'ข้อมูลสมาชิก',
    planDetail: 'รายละเอียดแพ็กเกจ',
    currentPlan: 'แผนการใช้บริการปัจจุบัน',
    expireDate: 'เริ่มใช้งานเมื่อ',
    startDate: 'เริ่มใช้งานเมื่อ',
    dialogSignOut: 'คุณต้องการออกจากระบบใช่หรือไม่?',
    confirmSignOut: 'กรุณากดตกลงเพื่อออกจากระบบ',
    countMember: 'สมาชิกทั้งหมด',
    addMenber: 'เพิ่มสมาชิก',
    searchMember: 'ค้นหาโดยชื่อ อีเมล หรือเบอร์โทรศัพท์',
    dateOfMember: 'เยี่ยมชมครั้งแรก',
    recentVisit: 'เยี่ยมชมครั้งล่าสุด',
    memberName: 'ชื่อสมาชิก',
    email: 'อีเมล',
    paymentTotal: 'ใช้จ่ายสะสม',
    point: 'แต้มสะสม',
    balancePoints: 'คะแนนคงเหลือ',
    pointtxt: 'คะแนน',
    postalCode: 'รหัสไปรษณีย์',
    saveing: 'กำลังบันทึก . . .',
    edit: 'แก้ไข',
    editBOM: 'แก้ไขรายการ',
    editMember: 'แก้ไขข้อมูลสมาชิก',
    deleteing: 'กำลังลบ . . .',
    dialogDeleteMember: 'คุณต้องการลบสมาชิก',
    confirmDeleteMember: 'ยืนยันการลบสมาชิก',
    valtdateMember: 'กรุณากรอกข้อมูลให้ครบ',
    createMemberSuccess: 'เพิ่มสมาชิกสำเร็จแล้ว',
    updateMemberSuccess: 'แก้ไขข้อมูลสมาชิกสำเร็จแล้ว',
    deleteMemberSuccess: 'ลบข้อมูลสมาชิกสำเร็จแล้ว',
    onhandQtyMin: 'จำนวนน้อยไปมาก',
    onhandQtyMax: 'จำนวนมากไปน้อย',
    onhandQtySort: 'จำนวนสินค้า',
    showProductAll: 'สินค้าทั้งหมด',
    updateAt: 'ปรับปรุงล่าสุด',
    sortName: 'ชื่อสินค้า',
    txtOptionCategory: 'กลุ่มตัวเลือก',
    salesQty: 'จำนวนการขาย',
    avgItemsPerBill: 'สั่งอาหารเฉลี่ย',
    avgNumCustPerBill: 'จำนวนลูกค้าเฉลี่ย',
    avgBillPerDay: 'จำนวนการใช้โต๊ะทั้งหมด',
    tableStatsReport: 'รายงานสถิติการใช้โต๊ะ',
    itemperbill: 'รายการ/รอบ/โต๊ะ',
    avgDurationPerBill: 'เวลาเฉลี่ย',
    tableUsage: 'จำนวนการใช้โต๊ะ',
    totalItems: 'รายการอาหารทั้งหมด',
    personperbill: 'คน/โต๊ะ',
    person: 'คน',
    totalTime: 'เวลาทั้งหมด',
    tableperbill: '/โต๊ะ',
    minperbill: 'ชั่วโมง/โต๊ะ',
    timeperday: 'รอบ/โต๊ะ/วัน',
    times: 'ครั้ง',
    tableStats: 'สถิติการใช้โต๊ะ',
    stockUnit: 'ราคา/หน่วย',
    selectd: 'เลือก',
    searchProduct: 'ค้นหาสินค้า',
    reset: 'รีเซต',
    selectdProduct: 'เลือกสินค้า',
    information: 'ข้อมูลเบื้องต้น',
    adjustStockList: 'รายการปรับปรุงสต๊อก',
    adjustDetail:
        'เพิ่มสินค้าลงรายการปรับปรุงสต๊อก และตั้งค่ายอดตรวจนับของสินค้าในแต่ละชิ้น',
    checkStockDetail: 'เพิ่มสินค้าลงเอกสารตรวจนับสต๊อก และใส่ยอดตรวจนับของสินค้าในแต่ละชิ้น',
    transferoutList: 'รายการโอนสินค้าระหว่างสาขา',
    addProductTransfer:
        'เพิ่มสินค้าลงรายการโอนสินค้าระหว่างสาขา และตั้งค่าส่วนลดของสินค้าในแต่ละชิ้น',
    transferDetail: 'รายการโอนสินค้าระหว่างสาขา และส่วนลดของสินค้าในแต่ละชิ้น',
    transferinList:
        'รายการสินค้ารับเข้าระหว่างสาขา และรายการส่วนลดของสินค้าในแต่ละชิ้น',
    stockOutList: 'รายการสินค้าจ่ายออก',
    addstockOutList:
        'เพิ่มสินค้าลงรายการจ่ายออก และตั้งค่าส่วนลดของสินค้าในแต่ละชิ้น',
    stockinList: 'รายการสินค้ารับเข้า',
    addProductStockin:
        'เพิ่มสินค้าลงรายการรับเข้า และตั้งค่าส่วนลดของสินค้าในแต่ละชิ้น',
    deleteCategory: 'ลบกลุ่มสินค้า',
    item: 'รายการ',
    itemQty: 'ชิ้น',
    from: 'จาก',
    service: 'บริการ',
    resource: 'ทรัพยากร',
    bookingNumber: 'หมายเลขการจอง',
    settingKitchenOpenTimesHeader: 'กำหนดระยะเวลาเปิด-ปิดครัว',
    settingKitchenOpenTimesDespt: 'กำหนดระยะเวลาเปิด-ปิดครัว สำหรับระบบสั่งอาหารด้วยตนเอง ลูกค้าสามารถสั่งอาหารได้ก็ต่อเมื่ออยู่ในช่วงเวลาครัวเปิดทำการเท่านั้น',
    settingKitchenOpenTimes: 'ตั้งค่าเวลาเปิด-ปิดครัว',
    applyToAllDay: 'เซ็ตเป็นเวลาเดียวกันทุกวัน',
    openTimes: 'เวลาเปิดร้าน',
    closeTimes: 'เวลาปิดร้าน',
    closeOn: 'ปิดร้าน',
    days: 'วัน',
    mounth: 'เดือน',
    weeks: 'สัปดาห์',
    years: 'ปี',
    dashboardBook: 'รายการจอง',
    resourceType: 'ประเภททรัพยากร',
    categoryBooking: 'หมวดหมู่',
    memberDetail: 'ข้อมูลสมาชิก',
    package: 'รายละเอียดแพ็กเกจ ',
    startPackate: 'เริ่มใช้งานเมื่อ',
    endPackate: 'ใช้งานได้ถึง',
    name: 'ชื่อสินค้า',
    productImg: 'ภาพสินค้า',
    fistname: 'ชื่อ',
    lastname: 'สกุล',
    saveMember: 'บันทึกข้อมูล',
    confirmSaveMember: 'คุณต้องการยืนยันการบันทึกข้อมูลใช่หรือไม่',
    memberSource: 'ช่องทาง',
    yesOrNo: 'ใช่หรือไม่',
    totaValue: 'มูลค่ารวม',
    deleteMember: 'ลบสมาชิก',
    frequently: 'สินค้าที่ซื้อบ่อย',
    recentPurchase: 'การซื้อล่าสุด',
    paymentDescription: 'รายละเอียดการขาย',
    validateInfomation: 'กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน',
    validateTel: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
    validateName: 'ชื่อไม่ถูกต้อง',
    validateTaxID: 'เลขประจำตัวผู้เสียภาษี/เลขนิติบุคคล ไม่ถูกต้อง',
    validateAddress: 'ที่อยู่ไม่ถูกต้อง',
    validateEmail: 'อีเมลไม่ถูกต้อง',
    saveSuccess: 'บันทึกข้อมูลสำเร็จ',
    saveSuccessDialog: 'บันทึกการนำเข้าสินค้าเรียบร้อยแล้ว ',
    saveSuccessDialog2: 'กรุณาตรวจสอบความถูกต้องของสินค้า',
    alertImportProductDialog: 'สินค้าที่ตรวจสอบไม่ผ่านจะไม่ถูกบันทึก',
    txtcustomer: 'รายชื่อลูกค้า',
    customerName: 'ชื่อลูกค้า',
    customerCount: 'ลูกค้าทั้งหมด',
    addCustomer: 'เพิ่มลูกค้า',
    permissionManage: 'สิทธิการเข้าถึง',
    mainMenu: 'เมนูหลัก',
    page: 'หน้า',
    show: 'แสดง',
    delete: 'ลบ',
    administratorTools: 'เครื่องมือสำหรับผู้ดูแลระบบ',
    deviceName: 'ชื่ออุปกรณ์',
    lastSyncProduct: 'เวลาใช้งานล่าสุด',
    version: 'เวอร์ชั่น',
    posstatus: 'สถานะการใช้งาน',
    posNumber: ' หมายเลขเครื่อง POS',
    titleSilomconnect: 'ไม่พลาดเหตุการณ์สำคัญ รับแจ้งเตือนผ่าน Line Alert ',
    updateSilom: 'แอดไลน์ @SilomPOS เพื่อเปิดใช้งาน',
    addfriend: 'เพิ่มเพื่อน',
    enterToReciveService: 'กดรับรหัสสำหรับใช้บริการ',
    verifyCodeToUsed:
        'เมื่อเพิ่มเพื่อนเรียบร้อยแล้ว "กดรับรหัส" เพื่อใช้ในการยืนยันตัวตน เพื่อเริ่มต้นใช้งานระบบ',
    verifyfivemin: 'รหัสยืนยันสามารถใช้ได้ภายใน 5 นาที',
    yourVertify: 'รหัสของคุณคือ',
    pressCode: 'กดรับรหัส',
    exCode: 'รหัสหมดอายุภายใน',
    sessionEx: 'เซสชันการเข้าสู่ระบบหมดอายุ กรุณาล็อกอินใหม่อีกครั้ง',
    refresh: 'รีเฟรช',

    selectedFile: 'เลือกไฟล์',
    fileSize: 'ขนาดไฟล์แนะนำ ไม่เกิน 2 MB',
    isBeingToUsed: 'กำลังถูกใช้งาน',
    notInUse: 'ว่างจากการใช้งาน',
    confirmUpdateInformation: 'ยืนยันการอัปเดตข้อมูล',
    memberCount: 'สมาชิกทั้งหมด',
    orderMachine: 'เครื่อง Order',
    textMember: 'รายชื่อสมาชิก',
    manageShop: 'จัดการข้อมูลร้านค้าเบื้องต้น',
    lansetting: 'ตั้งค่าภาษา และโซนเวลาเบื้องต้นสำหรับร้านค้า',
    timezoneSetiing: 'ตั้งค่าโซนเวลาสหรับร้านค้าเบื้องต้น',
    deliveryProviderSetting: 'รายชื่อผู้ให้บริการเดลิเวอรี่ สำหรับร้านค้า',
    toolsSetting: 'จัดการเครื่อง POS สำหรับร้านค้า',
    checkStockPLU: 'สินค้าคงเหลือ < 0',
    checkStockPLUMsg: 'สินค้าคงเหลือสาขาต้นทางเหลือน้อยกว่า 0 กรุณาตรวจสอบสินค้าคงเหลือ',
    searching: 'กำลังค้นหา',
    allProduct: 'สินค้าทั้งหมด',
    foundProductOnhand: 'พบจำนวนคงเหลือในขนาดบรรจุอื่นคงเหลือน้อยกว่า 0',
    confirmAdjustBefor: 'ท่านต้องการให้ระบบปรับปรุงอัตโนมัติหรือไม่',
    requireAdjust: 'ต้องการ',
    imrequireAdjust: 'ไม่ต้องการ',
    islimitItemTodoc: 'เลือกรายการสินค้าได้ไม่เกิน 200 รายการ / 1 เอกสาร',
    barcodePrint: 'พิมพ์บาร์โค้ด',
    printEx: 'ตัวอย่าง & พิมพ์',
    print: 'พิมพ์',
    downloadAndExport: 'ดาวน์โหลดและพิมพ์',
    selectFormat: 'เลือกรูปแบบกระดาษ',
    peperFormat: 'รูปแบบกระดาษ', //รูปแบบกระดาษ
    optional: 'ตัวเลือกเสริม', //Optional
    standardA427: 'มาตรฐาน A4 : 27 รายการ / แผ่น',
    standardA450: 'มาตรฐาน A4 : 52 รายการ / แผ่น',
    standardA460: 'มาตรฐาน A4 : 60 รายการ / แผ่น',
    standardA556: 'มาตรฐาน A4  : 56 รายการ / แผ่น (สติ๊กเกอร์ขนาด A5)',
    standardA550: 'ขนาดกระดาษ A5 : 52 รายการ / แผ่น',
    standardA560: 'ขนาดกระดาษ A5 : 60 รายการ / แผ่น',
    countBarcode: 'จำนวนบาร์โค้ด',
    barcode: 'รหัสบาร์โค้ด',
    setFontsize: 'กำหนดตัวอักษร',
    accessPermissionDes: 'กำหนดสิทธิการเข้าถึงสำหรับผู้ใช้',
    deleteUser: 'ยืนยันการลบผู้ใช้',
    confirmDeleteUser: 'คุณต้องการลบผู้ใช้นี้ หรือไม่?',
    confirmUpdateUserInfo: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่?',
    passAtless: 'รหัสควรมีความยาวอย่างน้อย 6 ตัวอักษร',
    inValidPass: 'รหัสผ่านไม่ถูกต้อง',
    createBy: 'สร้างโดย',
    adjustAutoDes:
        'หากไม่ต้องการให้ระบบปรับปรุงอัตโนมัติ อาจทำให้ยอดจำนวนคงเหลือรวมสินค้าผิดพลาดได้',
    addUser: 'เพิ่มผู้ใช้',
    isMenu: 'แสดงบนเมนูลูกค้า',
    menuSmart: 'จัดการเมนูสินค้า',
    smartSettingTitle: 'ตั้งค่าแสดงเมนูสินค้า',
    onoffMenu: 'เปิด/ปิดเมนูสำหรับ Kiosk และ QR-Order',
    smartSetting: 'ตั้งค่าเมนูสินค้า สำหรับระบบสมาชิกสะสมแต้ม - CRM ',
    confirmEnable: 'ท่านต้องการเปิดการใช้งานเมนูสินค้าหรือไม่',
    confirmDisEnable: 'ท่านต้องการปิดการใช้งานเมนูสินค้าหรือไม่',
    confirmSelfOrderEnable: 'ท่านต้องการเปิดการใช้งานสั่งซื้อด้วยตนเองหรือไม่',
    confirmSelfOrderDisable: 'ท่านต้องการปิดการใช้งานสั่งซื้อด้วยตนเองหรือไม่',
    enable: 'เปิดใช้งาน',
    freeTrial: 'ลองฟรี',
    disable: 'ปิดใช้งาน',
    usabilityStatus: 'สถานะการใช้งาน',
    manageShopDes: 'ท่านสามารถดูคู่มือการใช้งานได้',
    manageSmartOnProduct:
        'ท่านสามารถ "เพิ่ม/แก้ไข" รายการสินค้าที่ต้องการแสดงบนเมนูลูกค้ได้ที่ หน้า',
    manageSmartOnCategory:
        'ท่านสามารถ "เพิ่ม/แก้ไข" กลุ่มสินที่ต้องการแสดงบนเมนูลูกค้าได้ที่ หน้า',
    turnonSmart: 'และเลือกแสดงบนเมนูลูกค้า',
    desSmartMenu:
        'การตั้งค่าเมนู สำหรับแสดงสินค้าในรูปแบบ e-menu แสดงบน Line  เพื่อให้ลูกค้าเข้าถึงได้ง่ายขึ้น และสามารถสั่งสินค้าด้วยตนเองได้',
    link: 'ที่นี่',
    createTranferOut: 'สร้างเอกสารโอนสินค้าออก',
    tranferOut: 'เอกสารโอนสินค้าออก',
    roundingValue: 'ปัดเศษ',
    limitItems: 'สามารถเพิ่มสินค้าได้ไม่เกิน 200 รายการ',
    IncomeCategory: 'รายรับ',
    ExpenseCategory: 'รายจ่าย',
    saveBy: 'บันทึกโดย',
    saveDate: 'วันทึกวันที่',
    drawerCate: 'หมวดหมู่',
    openingHours: 'เวลาเปิด-ปิดร้าน',
    shopLocation: 'ตำแหน่งร้านค้า',
    sunday: 'วันอาทิตย์',
    monday: 'วันจันทร์',
    tuesday: 'วันอังคาร',
    wednesday: 'วันพุธ',
    thursday: 'วันพฤหัสบดี',
    friday: 'วันศุกร์',
    saturday: 'วันเสาร์',
    sun: 'อา',
    mon: 'จ',
    tues: 'อ',
    wed: 'พ',
    thur: 'พฤ',
    fri: 'ศ',
    sat: 'ส',
    lat: 'ละติจูด',
    lng: 'ลองจิจูด',
    shopCoverSetting: 'ตั้งค่าหน้าปก',
    shopCoverSettingDes: 'ตั้งค่าภาพหน้าปกร้านค้า เพื่อแสดงผลฝั่งลูกค้า',
    locationSettingDes: 'ระบุตำแหน่งที่ตั้งของร้านค้า',
    updatelocation: 'อัปเดตตำแหน่งที่ตั้งร้านค้า', //Update location
    searchLocation: 'ค้นหาตำแหน่ง', //Search Location
    imaginaryLine: 'ระบุพิกัด', //Imaginary Line
    openingHoursDes: 'ตั้งค่ากำหนดเวลาเปิดปิดร้าน สำหรับร้านค้า',
    paymentDescriptionConfig: 'เลือกประเภทการชำระเงินสำหรับร้านค้า',
    creditCard: 'บัตรเครดิต/เดบิต',
    promptPay: 'โอน (พร้อมเพย์)',
    coupon: 'คูปอง',
    quickpay: ' Krungsri Quick Pay',
    alipay: 'Alipay',
    linepay: 'Rabbit LINE Pay',
    thaidotcom: 'Thai Dot Com Payment',
    dayMY: ' วัน/เดือน/ปี',
    dayMYBirth: ' วัน/เดือน/ปี เกิด',
    byStockIn: 'ชื้อสินค้า',
    stockOutOther: 'ค่าใช้จ่ายอื่นๆ',
    drawerSales: 'ขายสินค้า',
    loss: 'ขาดทุน',
    sumIncomeExpenses: 'สรุป รายรับ-รายจ่าย สำหรับเดือน', //Summary of income-expenses for the month
    incomeOther: 'รายรับอื่นๆ',
    grossProfit: 'กำไรสุทธิ',
    newUser: 'เพิ่มผู้ใช้',
    userType: 'ประเภทผู้ใช้',
    password: 'รหัสผ่าน',
    confirmPassword: 'ยืนยันรหัสผ่าน',
    ParticipatingProducts: 'เลือกรายการสินค้า',
    descriptionSilomOrder:
        'เลือกรายการสินค้าสำหรับแสดงบน mobile-qr.silompos.app ซึ่งลูกค้าสามารถทำการสั่งอาหารได้ด้วยตนเอง',
    descriptionFreeTrialLine4:
        'รอเปิดบริการ 5 วันทำการ โปรดทิ้งเบอร์ติดต่อและอีเมลเพื่อตรวจสอบสิทธิ',
    descriptionFreeTrialLine3:
        'การใช้ QR Order ต้องใช้ Smart+ และสมัคร QR ORDER 1800 บาท ',
    descriptionFreeTrialLine2:
        'สนใจสมัครเพื่อทดลองได้ ไม่ผูกมัด สำหรับแพ็คเกจ Smart Plus ทดลอง 3 เดือน และ Starter+ ทดลอง 1 เดือน',
    descriptionFreeTrialLine1:
        'SILOM POS เปิดให้ลูกค้าทดลองใช้ QR ORDER กับลูกค้าปัจจุบันที่สมัครเป็นรายปี ',
    addLine: 'สนใจติดต่อไลน์แอด Silom Official',
    cashierManagement: 'จัดการพนักงาน',
    cashierManageButton: 'จัดการข้อมูล',
    cashierUsernamePassword: 'สร้างชื่อผู้ใช้/รหัสผ่าน',
    cashierCRUDDescription: 'สำหรับเข้าใช้หน้าเว็บ mobile-qr.silompos.app',
    password: 'รหัสผ่าน',
    placeholderPassword: 'กรอกรหัสผ่านอย่างน้อย 6 ตัวอักษร',
    resetPassword: 'รีเซ็ตรหัสผ่าน',
    oldPassword: 'รหัสผ่านเดิม',
    oldPasswordPlaceholder: 'กรอกรหัสผ่านเดิม',
    newPassword: 'รหัสผ่านใหม่',
    purchaseDetail: 'รายละเอียดการซื้อ',
    uploadImage: 'อัปโหลดรูปภาพ',
    uploadFile: 'อัปโหลดไฟล์',
    downloadFileExample: 'ดาวน์โหลดไฟล์ตัวอย่าง',
    download: 'ดาวน์โหลด',
    totalOfImportProduct: 'จำนวนสินค้าที่นำเข้า',
    keyword: 'กรอกคำค้นหา',
    firstname: 'ชื่อ',
    lastname: 'นามสกุล',
    province: 'จังหวัด',
    district: 'เขต/อำเภอ',
    subdistrict: 'แขวง/ตำบล',
    addressNumber: 'เลขที่/ถนน/ตึก/อาคาร',
    setAsDefaultAddress: 'เลือกเป็นที่อยู่ตั้งต้น',
    sex: 'เพศ',
    male: 'ชาย',
    female: 'หญิง',
    notSpectified: 'ไม่ระบุ',
    verify: 'ตรวจสอบ',
    setAsTaxAddress: 'ตั้งเป็นที่อยู่สำหรับออกใบกำกับภาษี',
    setAsShippingAddress: 'ตั้งเป็นที่อยู่สำหรับจัดส่งสินค้า',
    contact: 'ช่องทางติดต่อ',
    setAsDefault: 'ตั้งเป็นค่าเริ่มต้น',
    addressLists: 'รายการที่อยู่',
    default: 'ค่าเริ่มต้น',
    taxAddress: 'ออกใบกำกับภาษี',
    shippingAddress: 'จัดส่งสินค้า',
    addAddress: 'เพิ่มที่อยู่',
    createdAt: 'วันที่สร้าง',
    updatedAt: 'ปรับปรุงเมื่อ',
    by: 'โดย',
    unlock: 'ปลดการใช้งานเครื่อง POS',
    unlockTitle: "คุณต้องการปลดการใช้งานหมายเลขเครื่อง {posNumber} หรือไม่",
    unlockDescription: "เมื่อปลดการใช้งานแล้วสิทธิ์การบันทึกและส่งข้อมูลขายจะถูกถอดออกจากเครื่อง<br>เพื่อให้อุปกรณ์ใหม่เข้าใช้งานในแผนบริการเดิม",
    unlockSuccess: "ปลดการใช้งานสำเร็จแล้ว!",
    readyForSale: 'พร้อมขาย',
    sold: 'ขายแล้ว',
    delete: 'ลบ',
    chooseTimezone: 'เลือกโซนเวลา',
    items: 'รายการสินค้า',
    itemDiscount: 'ส่วนลดรายการ',
    failedToSave: 'ไม่สามารถบันทึกสินค้าได้!',
    dateOfBirth: 'วันเกิด',
    menu: 'เมนู',
    dashboardMenu: 'รายงานสรุป',
    transactionMenu: 'ประวัติการขาย',
    currentBillMenu: 'บิลที่เปิดอยู่',
    reportMenu: 'รายงาน',
    orderDeviceMenu: 'เครื่อง Order',
    memberMenu: 'สมาชิก',
    inventoryMenu: 'งานคลังสินค้า',
    productMenu: 'สินค้า',
    selfOrderMenu: 'สั่งซื้อด้วยตนเอง',
    settingMenu: 'การตั้งค่า',
    salesReportByDate: 'ยอดขายสินค้าตามวัน',
    salesReportByOptional: 'ยอดขายตามตัวเลือก',
    salesReportByBill: 'ยอดขายตามรายละเอียดบิล',
    salesQuantitySummaryByProductReport: 'ยอดขายตามสินค้า',
    quantitySummaryByPLUReport: 'ยอดขายสินค้าตามขนาดบรรจุ',
    nonSellingProduct: 'สินค้าที่ไม่มีการขาย',
    salesSummaryByCashierReport: 'ยอดขายแยกตามพนักงาน',
    salesSummaryByCategoryReport: 'การขายแยกตามกลุ่มสินค้า',
    paymentReport: 'การชำระเงิน',
    salesTaxReport: 'สรุปภาษีขาย',
    drawerReport: 'ปิดรอบการขาย',
    voidBillReport: 'ยกเลิกการขาย',
    timesheetReport: 'ชั่วโมงการทำงาน',
    stockInByListReport: 'รับสินค้าเข้าแสดงรายการ',
    stockOutByListReport: 'จ่ายสินค้าออกแสดงรายการ',
    nonAdjustStockProductReport: 'สินค้าที่ยังไม่ปรับปรุงสต๊อก',
    exportProductReport: 'ส่งออกรายการสินค้า',
    optionalReport: 'ยอดขายตามตัวเลือก',
    newPaymentReport: 'บิลที่ออกใบกำกับภาษี',
    cashInOutReport: 'นำเงินเข้า-นำเงินออก',
    salesChannelsReport: 'ยอดขายตามช่องทางการขาย',
    txtTaxFowAcc: 'สรุปภาษีตาม Flow Account',
    taxFlowAccReport: 'รายงานสรุปภาษีตาม Flow Account',
    txtSalesChannels: 'ช่องทางการขาย',
    tableSummaryReport: 'รายงานสรุปการใช้โต๊ะ',
    beforeGP: 'ก่อนหัก GP',
    afterGP: 'ค่า GP',
    billingCycle: 'รอบการขาย',
    cashIn: 'นำเงินเข้า',
    cashOut: 'นำเงินออก',
    salesSummaryByOrderReport: 'ยอดขายสินค้า',
    salesSummaryProductByOrderReport: 'สินค้าขายได้',
    voidBillByOrderReport: 'ยกเลิกการขายสินค้า',
    inventoryReport: 'สินค้าคงเหลือตาม SKU',
    inventoryReportByPLU: 'สินค้าคงเหลือตามขนาดบรรจุ',
    inventoryReportBySerial: 'สินค้ามี Serial No.',
    inventoryReportByLessStock: 'สินค้าเหลือน้อย',
    inventoryReportByOutOfStock: 'สินค้าหมด',
    stockMovement: 'ความเคลื่อนไหวสินค้า',
    stockInDocument: 'รับสินค้าเข้า',
    stockOutDocument: 'จ่ายสินค้าออก',
    adjustStockDocument: 'ปรับปรุงสต๊อก',
    selfOrder: 'สั่งอาหารด้วย QR-ORDER ',
    selfOrdertxt: 'สั่งอาหาร QR-ORDER ',
    cashier: 'พนักงาน',
    permission: 'สิทธิการเข้าถึง',
    menuSetting: 'ตั้งค่า E-Menu (CRM)',
    today: 'วันนี้',
    yesterday: 'เมื่อวาน',
    last7days: '7 วันล่าสุด',
    last30days: '30 วันล่าสุด',
    thismonth: 'เดือนนี้',
    lastmonth: 'เดือนที่แล้ว',

    report: {
        dashboard: 'รายงานสรุป',
        transaction: 'ประวัติการขาย',
        currentbill: 'บิลที่เปิดอยู่',
        daily: 'ยอดขายตามวัน',
        txtdaily: 'รายงานยอดขายสินค้าตามวัน',
        dailyDes: 'รายละเอียดยอดขายสินค้าตามวัน',
        sellbyProduct: 'การขายตามรายละเอียดบิล',
        txtsellbyProduct: 'รายงานยอดขายตามรายละเอียดบิล',
        sku: 'ยอดขายตามสินค้า',
        txtsku: 'รายงานยอดขายตามสินค้า',
        plu: 'ยอดขายสินค้าตามขนาดบรรจุ',
        textPlu: 'รายงานยอดขายสินค้าตามขนาดบรรจุ',
        paymentreport: 'การชำระเงิน',
        txtpaymentreport: 'รายงานการชำระเงิน',
        timesheet: 'ชั่วโมงการทำงาน',
        txtTimesheet: 'รายงานชั่วโมงการทำงาน ',
        unsell: 'สินค้าที่ไม่มีการขาย',
        txtUnsell: 'รายงานสินค้าที่ไม่มีการขาย',
        byCategory: 'ยอดขายตามกลุ่มสินค้า',
        byCashier: 'ยอดขายตามพนักงาน',
        salesTax: 'สรุปภาษีการขาย',
        txtSalesTax: 'รายงานสรุปภาษีขาย',
        voidbill: 'ยกเลิกการขาย',
        txtvoidbill: 'รายงานยกเลิกการขาย',
        closedSale: 'ปิดรอบการขาย',
        txtCloseSale: 'รายงานปิดรอบการขาย',
        salesByOrder: 'ยอดขาย',
        productByOrder: 'ยอดขายตามสินค้า',
        unsalesByOrder: 'ยกเลิกการขาย',
        txtbyCategory: 'รายงานการขายแยกตามกลุ่มสินค้า ',
        txtbyCashier: 'รายงานยอดขายแยกตามพนักงาน',
        txtDeliveryRoport: 'รายงานการชำระเงินตามรายชื่อ Delivery',
        txtCustomReport: 'รายงานการชำระเงินประเภทอื่นๆ',
        txtSalesByOrder: 'รายงานสินค้าขายได้ตามเครื่อง Order',
        txtDailyOrder: 'รายงานยอดขายสินค้าตามเครื่อง Order',
        txtViodOrder: 'รายงานยกเลิกการขายสินค้าตามเครื่อง Order',
        nonadjust: 'สินค้าที่ยังไม่ปรับปรุงสต๊อก',
        txtnonadjust: 'รายงานสินค้าที่ยังไม่ปรับปรุงสต๊อก',
        stockInReport: 'รายงานรับสินค้าเข้าแสดงรายการ',
        stockOutReport: 'รายงานจ่ายสินค้าออกแสดงรายการ',
        stockIn: 'รับสินค้าเข้าแสดงรายการ',
        stockOut: 'จ่ายสินค้าออกแสดงรายการ',
        exportProduct: 'ส่งออกรายการสินค้า',
        txtExportProduct: 'รายงานส่งออกรายการสินค้า',
        cashDrawer: 'จัดการเงินสด',
        txtCashDrawerr: 'รายงานการจัดการเงินสดประจำรอบแบบแสดงรายละเอียด',
        txtOptionalsales: 'รายงานยอดขายตามตัวเลือก',
        txtTaxSummary: 'รายงานบิลที่ออกใบกำกับภาษี',
        txtCashInOut: 'รายงานการนำเงินเข้า-นำเงินออก',
        txtSalesChannelsReport: 'รายงานยอดขายตามช่องทางการขาย'

    },
    drawer: {
        cashDrawer: 'จัดการเงินสด',
        txtCashDrawerr: 'รายงานการจัดการเงินสดประจำรอบแบบแสดงรายละเอียด',
        cashRevenue: 'รายรับ-รายจ่าย',
        txtcashRevenue: 'รายงานรายรับ-รายจ่าย',
    },
    management: {
        txtManagement: 'จัดการข้อมูล',
        user: 'บัญชีร้านค้า',
        shopBranch: 'ร้านค้าและสาขา',
        productManagement: 'การจัดการสินค้า',
        unitManagement: 'หน่วยบรรจุ',
        categorytManagement: 'กลุ่มสินค้า',
        optionalGroup: 'กลุ่มตัวเลือกเสริม',
        inventory: 'งานคลังสินค้า',
        stockIn: 'รับสินค้าเข้า',
        stockOut: 'จ่ายสินค้าออก',
        adjustStock: 'ปรับปรุงสต๊อก',
        checkStock: 'Check stock',
        stockMovement: 'ความเคลื่อนไหวสินค้า',
        transferStock: 'โอนสินค้าระหว่างสาขา',
        reportByPLU: 'สินค้าคงเหลือตามขนาดบรรจุ',
        reportBySKU: 'สินค้าคงเหลือตาม SKU',
        txtreportBySKU: 'รายงานสินค้าคงเหลือตาม SKU',
        txtreportByPLU: 'รายงานสินค้าคงเหลือตามขนาดบรรจุ',
        stockInDoc: 'เอกสารรับสินค้าเข้า',
        stockOutDoc: 'เอกสารจ่ายสินค้าออก',
        adjustStockDoc: 'เอกสารปรับปรุงสต๊อก',
        transferStockDoc: 'เอกสารโอนสินค้าระหว่างสาขา (TO)',
        transferStockInDoc: 'เอกสารรับสินค้าระหว่างสาขา (TI)',
        transferStockOutDoc: 'เอกสารโอนสินค้าระหว่างสาขา',
        txtreportByLessStock: 'รายงานสินค้าเหลือน้อย',
        txtreportByOutOfStock: 'รายงานสินค้าหมด',
        productDeliveryManagement: 'จัดการเมนูเดลิเวอร์รี่'
    },
    member: {
        txtmember: 'สมาชิก',
        register: 'ลงทะเบียนร้านค้า (LINE OA)',
        loyaltyPoint: 'คะแนนสะสม',
        storeCredit: 'เครดิตสะสม',
        deal: 'ดีลสินค้า',
        giftVoucher: 'บัตรกำนัล',
        referral: 'แนะนำเพื่อน',
        crm: 'ระบบสมาชิก',
    },
    unableStockDescription:
        'ไม่สามารถแก้ไขสินค้าได้เนื่องจากมีการปรับปรุงสต๊อกภายหลังการสร้างเอกสาร',
    noItem: 'ไม่มีรายการ',
    enterProductName: 'กรอกชื่อสินค้า',
    ByCategory: 'ตามกลุ่มสินค้า',
    ByCategoryDetail: 'ตามรายละเอียดสินค้า',
    totalStockIn: 'รวมมูลค่ารับเข้า',
    totalStockOut: 'รวมมูลค่าจ่ายออก',
    totalDiscount: 'ส่วนลดรวม',
    stockInQty: 'จำนวนรับเข้า',
    stockOutQty: 'จำนวนจ่ายออก',
    documentQty: 'จำนวนเอกสาร',
    beforeDiscount: 'ยอดรวมก่อนลด',

    gmtLinewest: '(GMT-12:00) เส้นแบ่งวันสากล',
    gmtMidway: '(GMT-11:00) หมู่เกาะมิดเวย์, ซามัว',
    gmtHonolulu: '(GMT-10:00) ฮาวาย',
    gmtAlaska: '(GMT-09:00) อลาสก้า',
    gmtLos_Angeles: '(GMT-08:00) เขตแปซิฟิก (อเมริกา & แคนาดา)',
    gmtTijuana: '(GMT-08:00) ตีฮัวนา, บาฮากาลิฟอเนีย',
    gmtArizona: '(GMT-07:00) แอริโซนา',
    gmtChihuahua: '(GMT-07:00) ชิฮัวฮัว, ลาปาซ, มาซัตลาน',
    gmtMoutain: '(GMT-07:00) เขตภูเขา (อเมริกา & แคนาดา)',
    gmtManagua: '(GMT-06:00) อเมริกากลาง',
    gmtCentral: '(GMT-06:00) เขตเวลาตอนกลาง (อเมริกา & แคนาดา)',
    gmtMexico: '(GMT-06:00) กวาดาลาฮารา, เม็กซิโกซิตี้, มอนเตร์เรย์',
    gmtSaskatchewan: '(GMT-06:00) ซัสแคตเชวัน',
    gmtBogota: '(GMT-05:00) โบโกทา, ลิมา, กีโต, รีโอ บรังโก',
    gmtEastern: '(GMT-05:00) เขตเวลาตะวันออก (อเมริกา & แคนาดา)',
    gmtIndiana: '(GMT-05:00) อินเดียน่า (ตะวันออก)',
    gmtAtlantic: '(GMT-04:00) เขตแอตแลนติก (แคนาดา)',
    gmtCaracas: '(GMT-04:00) การากัส, ลาปาซ',
    gmtManaus: '(GMT-04:00) มาเนาซ์',
    gmtSantiago: '(GMT-04:00) ซานติเอโก้',
    gmtNewfoundland: '(GMT-03:30) เกาะนิวฟันด์แลนด์',
    gmtSao_Paulo: '(GMT-03:00) บลาซิเลีย',
    gmtBuenos_Aires: '(GMT-03:00) บัวโนสไอเรส, จอร์จทาวน์',
    gmtGodthab: '(GMT-03:00) กรีนแลนด์',
    gmtMontevideo: '(GMT-03:00) มอนเตวิเดโอ',
    gmtNoronha: '(GMT-02:00) กลางมหาสมุทรแอตแลนติก',
    gmtCape_Verde: '(GMT-01:00) กาบูเวร์ดี',
    gmtAzores: '(GMT-01:00) อะโซร์ส',
    gmtCasablanca: '(GMT+00:00) กาซาบล็องกา, มอนโรเวีย, เรคยาวิก',
    gmtGreenwich: '(GMT+00:00) เวลามาตรฐานกรีนิช : ดับลิน, เอดินเบิร์ก, ลิสบอน, ลอนดอน',
    gmtAmsterdam: '(GMT+01:00) อัมสเตอร์ดัม, เบอร์ลิน, เบิร์น, โรม, สตอร์กโฮม, เวียนนา',
    gmtBelgrade: '(GMT+01:00) เบลเกรด, บราติสลาวา, บูดาเปสต์, ลูบลิยานา, ปราก',
    gmtBrussels: '(GMT+01:00) บรัสเซลส์, โคเปนเฮเกน, มาดริด, ปารีส',
    gmtSarajevo: '(GMT+01:00) ซาราเยโว, สโกเปีย, วอร์ซอ, ซาเกร็บ',
    gmtLagos: '(GMT+01:00) แอฟริกากลางตะวันตก',
    gmtAmman: '(GMT+02:00) อัมมาน',
    gmtAthens: '(GMT+02:00) เอเธนส์, บูคาเรสต์, Istanbul',
    gmtBeirut: '(GMT+02:00) เบรุต',
    gmtCairo: '(GMT+02:00) ไคโร',
    gmtHarare: '(GMT+02:00) ฮาราเร, พริทอเรีย',
    gmtHelsinki: '(GMT+02:00) เฮลซิงกิ, เคียฟ, รีกา, โซเฟีย, ทาลลินน์, วิลนีอุส',
    gmtJerusalem: '(GMT+02:00) กรุงเยรูซาเล็ม',
    gmtMinsk: '(GMT+02:00) มินสค์',
    gmtWindhoek: '(GMT+02:00) วินด์ฮุก',
    gmtKuwait: '(GMT+03:00) คูเวต, ริยาด, แบกแดด',
    gmtTbilisi: '(GMT+03:00) ทบิลิซี',
    gmtTehran: '(GMT+03:30) กรุงเตห์ราน',
    gmtMuscat: '(GMT+04:00) อาบูดาบี, มัสกัต',
    gmtBaku: '(GMT+04:00) บากู',
    gmtYerevan: '(GMT+04:00) เยเรวาน',
    gmtKabul: '(GMT+04:30) คาบูล',
    gmtYekaterinburg: '(GMT+05:00) เยคาเตรินบุร์ก',
    gmtKarachi: '(GMT+05:00) กรุงอิสลามาบัด, การาจี, ทาชเคนต์',
    gmtCalcutta: '(GMT+05:30) กัลกัตตา',
    gmtChennai: '(GMT+05:30) เจนไน, กัลกัตตา, มุมไบ, นิวเดลี',
    gmtKatmandu: '(GMT+05:45) กาตมันดุ',
    gmtAlmaty: '(GMT+06:00) อัลมาตี, โนโวซีบีรสค์',
    gmtDhaka: '(GMT+06:00) อาสตานา, ธากา',
    gmtRangoon: '(GMT+06:30) ย่างกุ้ง',
    gmtBangkok: '(GMT+07:00) กรุงเทพ, ฮานอย, จากาตาร์',
    gmtHongkong: '(GMT+08:00) ปักกิ่ง, ฉงชิ่ง, ฮ่องกง, อุรุมชี',
    gmtKualaLumpur: '(GMT+08:00) กัวลาลัมเปอร์, สิงคโปร์',
    gmtIrkutsk: '(GMT+08:00) อีร์คุตสค์, อูลานบาตาร์',
    gmtPerth: '(GMT+08:00) เพิร์ธ',
    gmtTokyo: '(GMT+09:00) โอซาก้า, ซัปโปโร, โตเกียว',
    gmtSeoul: '(GMT+09:00) โซล',
    gmtYakutsk: '(GMT+09:00) ยาคุตสค์',
    gmtAdelaide: '(GMT+09:30) แอดิเลด',
    gmtDarwin: '(GMT+09:30) ดาร์วิน',
    gmtBrisbane: '(GMT+10:00) บริสเบน',
    gmtCanberra: '(GMT+10:00) แคนเบอร์ร่า, เมลเบิร์น, ซิดย์นี',
    gmtHobart: '(GMT+10:00) โฮบาร์ต',
    gmtGuam: '(GMT+10:00) กวม, พอร์ตมอร์สบี',
    gmtVladivostok: '(GMT+10:00) วลาดีวอสตอค',
    gmtMagadan: '(GMT+11:00) มากาดัน, เกาะโซโลมอน, นิวแคลิโดเนีย',
    gmtAuckland: '(GMT+12:00) ออกแลนด์, เวลลิงตัน',
    gmtFiji: '(GMT+12:00) ฟิจิ, คัมชัตคา, หมู่เกาะมาร์แชลล์',
    gmtTongatapu: '(GMT+13:00) นูกูอาโลฟา',
    menu: {
        newReportMenu: "แถบเมนูรายงานแบบใหม่แบ่งหัวข้อหลักเป็น 5 หัวข้อ ได้แก่ การขาย สินค้าคงคลัง ภาษี พนักงาน และเครื่อง Order",
        salesMenu: "ในเมนูการขาย จะแสดงรายงานต่าง ๆ ที่เกี่ยวกับยอดขายสินค้า ได้แก่ ยอดขายสินค้าตามวัน รายละเอียดบิล ยอดขายสินค้าตามสินค้า ขนาดบรรจุ ตัวเลือกสินค้า กลุ่มสินค้า การชำระเงิน การปิดรอบการขาย การยกเลิกการขาย และสถิติการใช้โต๊ะ",
        inventoryMenu: "ในแถบเมนูสินค้าคงคลังจะแสดงรายงานเกี่ยวกับคลังสินค้า ได้แก่ รายการรับสินค้า รายการจ่ายสินค้า สินค้าที่ไม่ได้ปรับปรุงสต๊อก รายการส่งออกสินค้า สินค้าคงเหลือตาม SKU ขนาดบรรจุ สินค้าที่มี Serial No. ความเคลื่อนไหวสินค้า สินค้าที่เหลือน้อย และสินค้าที่หมด",
        taxMenu: "แถบเมนูภาษีจะแสดงรายงานเกี่ยวกับภาษี ได้แก่ สรุปภาษีขายและบิลที่ออกใบกำกับภาษี",
        employeeMenu: "แถบเมนูพนักงานจะแสดงรายงานที่เกี่ยวข้องกับพนักงาน ได้แก่ ยอดขายแยกตามพนักงานและชั่วโมงการทำงาน",
        orderMachinesMenu: "แถบเมนูเครื่อง Order จะแสดงรายงานเกี่ยวกับเครื่อง Staff Order ได้แก่ ยอดขายสินค้า สินค้าที่ขายได้ และการยกเลิกการขายสินค้า",
        dataManagement: "ถัดจากรายงานเป็นส่วนจัดการข้อมูล ซึ่งแบ่งเป็น 3 เมนูหลัก ได้แก่ รายละเอียดสินค้า คลังสินค้า และร้านค้าและสาขา",
        productDetailsMenu: "แถบเมนูรายละเอียดสินค้าเป็นเมนูที่ใช้สำหรับการสร้าง แก้ไข หรือลบ รายการสินค้า กลุ่มสินค้า หน่วยบรรจุ ตัวเลือกเสริม และกลุ่มตัวเลือก อีกทั้งยังสามารถนำเข้ารายการสินค้าได้ทีละหลายรายการ",
        inventoryManagementMenu: "แถบเมนูคลังสินค้าใช้สำหรับการจัดการเอกสารเกี่ยวกับคลังสินค้า ซึ่งรายงานที่เกี่ยวข้องจะถูกจัดอยู่ในหมวดหมู่สินค้าคงคลัง โดยสามารถนำเข้าหรือจ่ายออกรายการสินค้า การปรับปรุงสต๊อกรายการสินค้าและตรวจนับรายการสินค้า",
        storeBranchMenu: "แถบเมนูร้านค้าและสาขาเป็นข้อมูลเกี่ยวกับการจัดการร้านค้าและสาขา",
        selfOrderingMenu: "แถบเมนูสั่งอาหารด้วยตัวเองประกอบด้วยเมนู สั่งอาหารด้วย QR-ORDER และเครื่องคีออส สำหรับร้านค้าที่เปิดใช้บริการ",
        membersMenu: "แถบเมนูสมาชิกประกอบด้วยเมนูสมาชิกของร้านค้าและระบบสมาชิกที่เชื่อมต่อไปยังเว็บไซต์ CRM ของร้านค้า",
        settingsMenu: "แถบเมนูการตั้งค่าประกอบด้วยเมนูการตั้งค่าร้านค้า ภาษาและโซนเวลา การชำระเงิน พนักงาน สิทธิการเข้าถึง รายชื่อผู้ให้บริการเดลิเวอรี่ เครื่องมือสำหรับผู้ดูแลระบบ ตั้งค่าเมนูสินค้า Silom Line Alert และแถบเมนูบัญชีร้านค้า"
    },
    menuDescription: "คำอธิบายการใช้งานแถบเมนู",
    printFoodOrderStickers: "พิมพ์สติ๊กเกอร์สั่งอาหาร",
    registerToReceiveFoodOrderStickers: "ลงทะเบียนรับสติ๊กเกอร์สั่งอาหาร",
    stickerSize: "ขนาดสติ๊กเกอร์: 10 x 10 ซม.",
    preciseEdgeCutting: "ตัดขอบอย่างประณีต",
    waterproofPVCMaterial: "วัสดุ PVC กันน้ำ",
    deliveryWithin14Days: "จัดส่งภายใน 14 วัน",
    firstTimeRegistrationFree: "ลงทะเบียนครั้งแรกฟรี",
    exportToPdf: 'ส่งออกเป็นไฟล์ PDF',
    exportingDespt: 'กำลังสร้างไฟล์ อาจใช้เวลาสักครู่...',
    copy: 'คัดลอก',
    settingSelfService: 'ตั้งค่าเปิด-ปิดบริการ Self Service',
    setDeliveryDomain: 'กำหนดชื่อโดเมนสำหรับเข้าระบบ Silom Delivery',
    setDeliveryDomainDespt: 'ลูกค้าสามารถเข้าระบบ Silom Delivery ผ่าน URL ที่กำหนด',
    setDeliveryDomainDespt2: 'โดยเมื่อบันทึกชื่อโดเมนแล้วจะไม่สามารถทำการแก้ไขได้ในภายหลัง',
    plsSetDomainName: 'กรุณากำหนดชื่อโดเมน',
    domianInUsed: 'มีร้านใช้ชื่อโดเมนนี้แล้ว',
    qrTypeSuggest: '• Dynamic QR, พนักงานต้องพิมพ์ใบสั่งอาหารใหม่ทุกครั้งเมื่อมีลูกค้าเข้ามาใช้บริการ<br>• Static QR, พิมพ์สติ๊กเกอร์เพียงครั้งเดียว ติดไว้ที่โต๊ะสำหรับสั่งอาหาร',
    sampleSticker: 'ตัวอย่างสติ๊กเกอร์ติดที่โต๊ะ',
    generatingStickerQR: 'กำลังสร้างไฟล์ PDF, ระยะเวลาการสร้างสติ๊กเกอร์จะขึ้นอยู่กับจำนวนโต๊ะที่มี',
    warningStaticQR: 'ร้านอาจมีความเสี่ยงที่จะถูกสั่งออเดอร์จากบุคคลอื่นภายนอก',
    promotion: 'โปรโมชัน',
    ITEM_FIXED_PRICE: 'ราคาโปรโมชันสินค้า',
    CATEGORY_FIXED_PRICE: 'ราคาโปรโมชันหมวดหมู่',
    ITEM_PERCENTAGE_DISCOUNT: 'ส่วนลดสินค้า %',
    ITEM_DOLLAR_DISCOUNT: 'ส่วนลดสินค้า ฿',
    CATEGORY_PERCENTAGE_DISCOUNT: 'ส่วนลดหมวดหมู่ %',
    CATEGORY_DOLLAR_DISCOUNT: 'ส่วนลดหมวดหมู่ ฿',
    RECEIPT_PERCENTAGE_DISCOUNT: 'ส่วนลดใบเสร็จ %',
    RECEIPT_DOLLAR_DISCOUNT: 'ส่วนลดใบเสร็จ ฿',
    FREE_ITEM: 'ฟรีสินค้า',
    FIXED_PRICE_OPTION: 'ราคาโปรโมชัน',
    DOLLAR_DISCOUNT_OPTION: 'ส่วนลดสินค้า (฿)',
    PERCENTAGE_DISCOUNT_OPTION: 'ส่วนลดสินค้า (%)',
    RECEIPT_DOLLAR_DISCOUNT_OPTION: 'ส่วนลดท้ายบิล (฿)',
    RECEIPT_PERCENTAGE_DISCOUNT_OPTION: 'ส่วนลดท้ายบิล (%)',
    FREE_ITEM_OPTION: 'ฟรีสินค้า',
    FIXED_PRICE_OPTION_DESP: 'ตั้งราคาพิเศษสำหรับสินค้าที่เข้าร่วมโปรโมชัน',
    DOLLAR_DISCOUNT_OPTION_DESP: 'ส่วนลดเป็นจำนวนเงินที่หักจากราคาสินค้าตามเงื่อนไขของโปรโมชัน',
    PERCENTAGE_DISCOUNT_OPTION_DESP: 'ส่วนลดเป็นเปอร์เซ็นต์จากราคาสินค้าตามเงื่อนไขของโปรโมชัน',
    RECEIPT_DOLLAR_DISCOUNT_OPTION_DESP: 'ส่วนลดเป็นจำนวนเงินที่หักท้ายบิลตามเงื่อนไขของโปรโมชัน',
    RECEIPT_PERCENTAGE_DISCOUNT_OPTION_DESP: 'ส่วนลดเป็นเปอร์เซ็นต์หักท้ายบิลตามเงื่อนไขของโปรโมชัน',
    FREE_ITEM_OPTION_DESP: 'มอบสินค้าฟรีเมื่อเป็นไปตามเงื่อนไขของโปรโมชัน',
    ITEM_LEVEL: 'สินค้า',
    CATEGORY_LEVEL: 'หมวดหมู่',
    RECEIPT_LEVEL: 'ใบเสร็จ',
    selectPromotion: "เลือกโปรโมชัน",
    selectPromotionDesp: "เลือกโปรโมชันที่ต้องการสร้าง เพื่อกระตุ้นยอดขายและสร้างแรงจูงใจในการซื้อสินค้า",
    selectPromotionLevel: "เลือกรายการตาม",
    selectPromotionLevelDesp: "กำหนดส่วนลดตามสินค้า หรือ ตามหมวดหมู่",
    selectCondition: "เลือกประเภทเงื่อนไข",
    selectConditionDesp: "เลือกเงื่อนไขที่ต้องการสำหรับโปรโมชันนี้",
    inputPromotionDetails: "กรอกรายละเอียดโปรโมชัน",
    inputPromotionDetailsDesp: "ระบุรายละเอียดของโปรโมชัน เช่น สิทธิประโยชน์ เงื่อนไขการใช้ หรือข้อกำหนดพิเศษ",
    BUNDLE_ITEM: "จับคู่สินค้า",
    BUNDLE_ITEM_DESP: "โปรโมชันนี้จะใช้ได้เมื่อลูกค้าซื้อสินค้าจับคู่ที่กำหนดไว้",
    BUNDLE_CATEGORY: "จับคู่หมวดหมู่",
    BUNDLE_CATEGORY_DESP: "โปรโมชันนี้จะใช้ได้เมื่อลูกค้าซื้อสินค้าจับคู่จากหมวดหมู่ที่กำหนดไว้",
    QUANTITY_ITEM_EXCEED: "สินค้าครบ (ชิ้น)",
    QUANTITY_ITEM_EXCEED_DESP: "โปรโมชันนี้จะใช้ได้เมื่อลูกค้าซื้อสินค้าจำนวนมากกว่าหรือเท่ากับจำนวนชิ้นที่กำหนด",
    QUANTITY_CATEGORY_EXCEED: "หมวดหมู่ครบ (ชิ้น)",
    QUANTITY_CATEGORY_EXCEED_DESP: "โปรโมชันนี้จะใช้ได้เมื่อลูกค้าซื้อสินค้าในหมวดหมู่จำนวนมากกว่าหรือเท่ากับจำนวนชิ้นที่กำหนด",
    QUANTITY_RECEIPT_EXCEED: "ใบเสร็จครบ (ชิ้น)",
    QUANTITY_RECEIPT_EXCEED_DESP: "โปรโมชันนี้จะใช้ได้เมื่อลูกค้าซื้อสินค้ารวมจำนวนมากกว่าหรือเท่ากับจำนวนชิ้นที่กำหนด",
    PRICE_ITEM_EXCEED: "สินค้าครบ (บาท)",
    PRICE_ITEM_EXCEED_DESP: "โปรโมชันนี้จะใช้ได้เมื่อลูกค้าซื้อสินค้าที่มีราคารวมถึงยอดที่กำหนด",
    PRICE_CATEGORY_EXCEED: "หมวดหมู่ครบ (บาท)",
    PRICE_CATEGORY_EXCEED_DESP: "โปรโมชันนี้จะใช้ได้เมื่อลูกค้าซื้อสินค้าที่มีราคารวมถึงยอดที่กำหนดจากหมวดหมู่ที่กำหนด",
    PRICE_RECEIPT_EXCEED: "ใบเสร็จครบ (บาท)",
    PRICE_RECEIPT_EXCEED_DESP: "โปรโมชันนี้จะใช้ได้เมื่อลูกค้าซื้อสินค้าที่มีราคารวมในใบเสร็จถึงยอดที่กำหนด",
    NO_CONDITION: "ไม่มีเงื่อนไข",
    NO_CONDITION_DESP: "โปรโมชันนี้ใช้ได้โดยไม่มีเงื่อนไขเพิ่มเติม",
    limit: 'จำกัด',
    BUNDLE_OPTION: 'จับคู่สินค้า',
    QUANTITY_EXCEED_OPTION: 'ซื้อครบ(ชิ้น)',
    PRICE_EXCEED_OPTION: 'ซื้อครบ(บาท)',
    createPromotion: 'สร้างโปรโมชัน',
    promotionActive: 'ใช้งานอยู่',
    promotionNotActive: 'ปิดใช้งานอยู่',
    startAt: 'เริ่มใช้',
    endAt: 'สิ้นสุด',
    exitPage: 'ออก',
    promotionCoverImg: 'รูปภาพปกโปรโมชัน (ถ้ามี)',
    promotionCoverImgDesp: 'ใช้แสดงหน้าร้านหรือสื่อออนไลน์เพื่อเพิ่มความน่าสนใจให้โปรโมชันของคุณและช่วยดึงดูดลูกค้าได้มากขึ้น แนะนำภาพสัดส่วน 16: 9 หรือ 1280x720 พิกเซล',
    promotionName: 'ชื่อโปรโมชัน',
    promotionNameDesp: 'กรอกชื่อโปรโมชัน เช่น ส่วนลดต้อนรับปีใหม่ ลดท้ายบิลทันที 20%',
    conditionInput: 'รายละเอียดโปรโมชัน',
    conditionInputDesp: 'กรอกรายละเอียด หรือ เงื่อนไขการใช้โปรโมชัน เช่น ลดทันทีเมื่อจับคู่ กาแฟร้อน กับ ครัวซองค์',
    promotionDuration: 'ระยะเวลาการใช้โปรโมชัน',
    promotionDurationDesp: 'กำหนดระยะเวลาของโปรโมชัน เช่น วันที่เริ่มต้นและสิ้นสุดของข้อเสนอ ',
    promotionPriority: 'ระดับความสำคัญของโปรโมชัน',
    promotionPriorityDesp: 'กำหนดระดับความสำคัญของโปรโมชันนี้ เพื่อจัดลำดับการใช้โปรโมชันเมื่อมีโปรโมชันหลายรายการ',
    promotionScope: 'ช่องทางการใช้โปรโมชัน',
    promotionScopeDesp: 'กำหนดช่องทางการใช้โปรโมชัน เช่น สำหรับขายหน้าร้าน หรือ แบบจัดส่ง',
    automaticPromotion: 'โปรโมชันอัตโนมัติ',
    automaticPromotionDesp: 'ตั้งค่าโปรโมชันอัตโนมัติที่ปรับใช้ตามเงื่อนไขที่กำหนด โดยโปรโมชันอัตโนมัติจะไม่สามารถตั้งค่าเป็นโปรโมชันแบบใช้รหัสอ้างอิงได้',
    useReferenceCode: 'ใช้โค้ดโปรโมชัน',
    useReferenceCodeDesp: 'กำหนดรหัสอ้างอิงที่ลูกค้าสามารถกรอกเพื่อรับสิทธิ์โปรโมชัน',
    defineRefCode: 'กำหนดรหัสอ้างอิง',
    promotionUsageLimit: 'จำกัดโควต้าการใช้โปรโมชัน',
    promotionUsageLimitDesp: 'กำหนดจำนวนสิทธิ์ในการใช้โปรโมชันนี้ภายในช่วงเวลาที่กำหนด',
    canCombineWithOtherPromotions: 'สามารถใช้ร่วมกับโปรโมชันอื่นได้',
    canCombineWithOtherPromotionsDesp: 'อนุญาตให้ใช้โปรโมชันนี้ร่วมกับโปรโมชันอื่นที่ใช้งานอยู่เพื่อเพิ่มประโยชน์สูงสุดให้กับลูกค้า',
    promotionUsageDaysLimit: 'จำกัดวันใช้งานโปรโมชัน',
    promotionUsageDaysLimitDesp: 'กำหนดวันที่สามารถใช้โปรโมชันนี้ได้ ช่วยควบคุมความพร้อมในการใช้งานสำหรับบางวันของสัปดาห์',
    promotionDetailHeader: 'รายละเอียดโปรโมชัน',
    promotionDetailHeaderDesp: 'ระบุรายละเอียดโปรโมชันเพื่ออธิบายสิทธิประโยชน์ วิธีการใช้ และเงื่อนไขที่เกี่ยวข้องให้ลูกค้าเข้าใจชัดเจน',
    conditionHeader: 'เงื่อนไข',
    conditionHeaderDesp: 'กำหนดเงื่อนไขสำหรับโปรโมชัน เช่น การซื้อขั้นต่ำหรือการจับคู่สินค้า เพื่อให้โปรโมชันมีความเฉพาะเจาะจงตามที่ต้องการ',
    bundles: 'จับคู่',
    QTYinput: 'จำนวน',
    selectCategoryInput: 'เลือกหมวดหมู่',
    whenBuyThese: 'เมื่อซื้อสินค้าเหล่านี้รวมกัน',
    piecesOrMore: "ชิ้นขึ้นไป",
    whenBuyCategoriesTogether: "เมื่อซื้อสินค้าในหมวดหมู่เหล่านี้รวมกัน",
    whenBuyItem: 'เมื่อซื้อสินค้า',
    whenTotalItemPrice: "เมื่อซื้อสินค้าราคารวม",
    whenTotalReceiptPrice: "เมื่อยอดบิลถึง",
    bahtOrMore: "บาทขึ้นไป",
    promotionHeader: "โปรโมชัน",
    itemHeader: "สินค้า",
    setPromotionPrice: "ตั้งราคาโปรโมชัน",
    currencyBaht: "บาท",//
    limitQTY: "จำกัด",//
    itemsPerBill: "รายการต่อบิล",
    categoryHeader: "หมวดหมู่",
    priceLimit: "ที่ราคาสินค้าไม่เกิน",
    addMoreDiscounts: "เพิ่มส่วนลดมากขึ้น",
    maximunDiscount: 'ลดสูงสุด',
    freeItem: 'แถมจำนวน',
    promotionDiscountTotal: 'ส่วนลดโปรโมชัน',
    promotionDiscount: 'ส่วนลดโปรโมชัน',
    freeItemLimit: 'จำกัดการใช้งานต่อออเดอร์',
    freeItemLimitDesp: 'สำหรับโปรโมชันฟรีสินค้า',
    X_FREE_Y_OPTION: 'ซื้อ X แถม Y',
    ITEM_X_FREE_Y: 'ซื้อ X แถม Y',
    CATEGORY_X_FREE_Y: 'ซื้อ X แถม Y ในหมวดหมู่',
    conditionHeader: 'เงื่อนไข',
    ITEM_DISCOUNT_OPTION: 'ส่วนลดสินค้า',
    CATEGORY_DISCOUNT_OPTION: 'ส่วนลดหมวดหมู่',
    RECEIPT_DISCOUNT_OPTION: 'ส่วนลดท้ายบิล',
    categoryNameHeader: 'ชื่อหมวดหมู่',
    stepPromotion1: 'รูปแบบส่วนลด',
    stepPromotion2: 'รูปแบบเงื่อนไข',
    stepPromotion3: 'กรอกเงื่อนไข',
    stepPromotion4: 'กรอกส่วนลด',
    stepPromotion5: 'รายละเอียดอื่น ๆ',
    inCorrectDiscount: 'ส่วนลดไม่ถูกต้อง',
    fixedPriceCanNotBeGreater: 'ราคาโปรโมชันต้องไม่สูงกว่าราคาปกติ',
    buy: 'ซื้อ',
    pieceGetFree: 'ชิ้น แถมฟรี',
    piece: 'ชิ้น',
    discountedTo: 'ลดเหลือ',
    highest: 'สูงสุด',
    lowest: 'ต่ำสุด',
    bathPerPiece: 'บาทต่อชิ้น',
    confirmSavePromotionHeader: 'ยืนยันการบันทึกโปรโมชัน',
    confirmSavePromotionDesp: 'การแก้ไขโปรโมชันสามารถทำได้ก่อนเวลาเริ่มโปรโมชัน อย่างน้อย 1 ชั่วโมง<br>เมื่อบันทึกโปรโมชันแล้ว โปรโมชันจะถูกเปิดใช้งานอัตโนมัติ ',
    promotion001: 'ส่วนลดสินค้า ฿',
    promotion002: 'ส่วนลดหมวดหมู่ ฿',
    promotion003: 'ส่วนลดท้ายบิล ฿',
    promotion004: 'ส่วนลดสินค้า %',
    promotion005: 'ส่วนลดหมวดหมู่ %',
    promotion006: 'ส่วนลดท้ายบิล %',
    promotion007: 'ฟรีสินค้า',
    promotion008: 'ราคาพิเศษ เมื่อซื้อถึงจำนวนชิ้น',
    promotion009: 'ลดราคา ฿ เมื่อซื้อถึงจำนวนชิ้น',
    promotion010: 'ลดราคา % เมื่อซื้อถึงจำนวนชิ้น',
    templatePromotion: 'เทมเพลตสำหรับสร้างโปรโมชัน',
    promotionTemplateDesp: 'สำหรับสร้างโปรโมชัน ช่วยให้คุณเลือกและปรับใช้เทมเพลต ที่เหมาะสมกับธุรกิจได้สะดวกและรวดเร็วประหยัดเวลาและเพิ่มประสิทธิภาพในการจัดโปรโมชัน',
    setShopOpenClose: 'ตั้งค่าร้านค้า',
    inventoryHelp: {
        transferOut: {
            title1: "วิธีการโอนย้ายสินค้าระหว่างสาขา",
            title2: "ขั้นตอนการรับสินค้าเข้าคลังระหว่างสาขา"
        },
        transferIn: {
            title1: "วิธีการโอนย้ายสินค้าระหว่างสาขา",
            title2: "ขั้นตอนการรับสินค้าเข้าคลังระหว่างสาขา"
        },
        inventoryStock: {
            title1: "การใช้งานคลังสินค้าของระบบ Silom POS"
        }
    },
    settingHelp: {
        shop: {
            title1: 'วิธีการตั้งค่าเวลาเปิด/ปิดร้านค้า',
            title2: 'วิธีการตั้งค่าร้านค้า Silom POS',
            title3: 'การตั้งค่าค่าบริการเพิ่มเติม (Service charge)',
            title4: 'การใช้งานโหมดร้านอาหาร และการใช้งาน Staff Order'
        },
        qrOrder: {
            title1: 'แนะนำ Mobile Order ระบบสั่งอาหารที่โต๊ะผ่านคิวอาร์โค้ด',
            title2: 'วิธีการตั้งค่าและใช้งาน Mobile Order รูปแบบ Static QR Code',
            title3: 'วิธีการตั้งค่าและใช้งาน Mobile Order รูปแบบ Dynamic QR Code'
        },
        staff: {
            title1: 'โหมดเปิดการใช้งานพนักงาน เช็คเวลาเข้า-ออกของพนักงาน',
            title2: 'ตั้งค่าสิทธิผู้ใช้งานเบื้องต้น'
        }
    },
    productHelp: {
        product: {
            title1: "การสร้างสินค้าประเภทสินค้าประกอบ(BOM)",
            title2: "การสร้างและขายสินค้าที่มีหลายขนาดบรรจุ",
            title3: "การสร้างสินค้าประเภทมีซีเรียล",
            title4: "สร้างสินค้าและขายแบบ Step price",
            title5: "การสร้างสินค้าประเภทสินค้าบริการ"
        },
        addproduct: {
            title1: "การตั้งค่าภาษีมูลค่าเพิ่ม (VAT) ใน SilomPOS",
            title2: "การสร้างราคาขายหน้าร้าน ราคาสมาชิกและราคาส่ง",
            title3: "การตั้งค่าค่าบริการเพิ่มเติม (Service charge)",
            title4: "วิธีการตั้งค่าระบบแจ้งเตือนสินค้าใกล้หมด",
            title5: "วิธีการตั้งค่าราคาขายตามช่องทาง Delivery",
            title6: "การใช้งาน topping หรือฟังก์ชั่นตัวเลือกเสริม"
        },
        option: {
            title1: "การใช้งาน topping หรือฟังก์ชั่นตัวเลือกเสริม"
        },
        import: {
            title1: "วิธีการนำเข้ารายการสินค้า (Import Product)"
        }
    },
    reportHelp: {
        daily: {
            title1: "ขั้นตอนการดูและ export รายงานสรุปยอดขายย้อนหลัง"
        },
        transaction: {
            title1: "วิธีการออกใบกำกับภาษี",
            title2: "วิธีการยกเลิกบิล"
        },
        transfer: {
            title1: "วิธีการโอนย้ายสินค้าระหว่างสาขา",
            title2: "ขั้นตอนการรับสินค้าเข้าคลังระหว่างสาขา"
        },
        payment: {
            title1: "การตั้งค่าการชำระเงินเเบบผสม",
            title2: "การตั้งค่าการชำระเงินเเบบกำหนดเอง",
            title3: "การตั้งค่าการเชื่อมต่อเครื่องชำระเงินเเบบ EDC ของ Kbank กับ POS"
        },
        staff: {
            title1: "โหมดเปิดการใช้งานพนักงาน เช็คเวลาเข้า-ออกของพนักงาน",
            title2: "ตั้งค่าสิทธิผู้ใช้งานเบื้องต้น"
        },
        closesell: {
            title1: "วิธีการตั้งค่าการเปิด/ปิดรอบการขาย",
            title2: "การจัดการเงินสด"
        },
        tax: {
            title1: "วิธีการออกใบกำกับภาษี"
        }
    },
    userHelp: {
        title1: "วิธีการต่ออายุแพ็กเกจ Silom POS",
        title2: "วิธีแก้ไขเมื่อร้านค้าลืมรหัสผ่านในระบบ Silom POS"
    },
    advanceCreate: 'กำหนดเอง',
    ITEM_FIXED_PRICE_DESP: 'กำหนดราคาพิเศษสำหรับสินค้าที่เลือก',
    CATEGORY_FIXED_PRICE_DESP: 'กำหนดราคาพิเศษสำหรับสินค้าที่อยู่ในหมวดหมู่ที่เลือก',
    ITEM_PERCENTAGE_DISCOUNT_DESP: 'มอบส่วนลดเป็นเปอร์เซ็นต์สำหรับสินค้าที่เลือก',
    ITEM_DOLLAR_DISCOUNT_DESP: 'มอบส่วนลดเป็นจำนวนเงินสำหรับสินค้าที่เลือก',
    CATEGORY_PERCENTAGE_DISCOUNT_DESP: 'มอบส่วนลดเป็นเปอร์เซ็นต์สำหรับสินค้าที่อยู่ในหมวดหมู่ที่เลือก',
    CATEGORY_DOLLAR_DISCOUNT_DESP: 'มอบส่วนลดเป็นจำนวนเงินสำหรับสินค้าที่อยู่ในหมวดหมู่ที่เลือก',
    RECEIPT_PERCENTAGE_DISCOUNT_DESP: 'มอบส่วนลดเป็นเปอร์เซ็นต์จากยอดใบเสร็จทั้งหมด',
    RECEIPT_DOLLAR_DISCOUNT_DESP: 'มอบส่วนลดเป็นจำนวนเงินจากยอดใบเสร็จทั้งหมด',
    FREE_ITEM_DESP: 'มอบสินค้าฟรีเป็นของแถมในโปรโมชัน',
    promotionExpired: 'โปรโมชันหมดอายุ',
    confirmEditPromotion: 'ยืนยันการแก้ไขข้อมูลโปรโมชัน',
    confirmCreatePromotion: 'ยืนยันการสร้างโปรโมชัน',
    confirmDeletePromotion: 'ยืนยันการลบโปรโมชัน',
    confirmDeletePromotionDesp: 'เมื่อคุณยืนยันการลบ โปรโมชันนี้จะหยุดใช้งานทันที<br>กรุณาตรวจสอบให้แน่ใจก่อนทำการลบ',
    promotionFeatures: [
        {
            title: "",
            description: "รองรับการสร้างแคมเปญ Grab Food"
        },
        {
            title: "",
            description: "ระบบสร้างโปรโมชันได้ยืดหยุ่น มากกว่า 63 รูปแบบ "
        },
        {
            title: "",
            description: "ระบบเทมเพลต ง่ายต่อการสร้างโปรโมชัน"
        },
        {
            title: "",
            description: "ระบบวันเวลาเฉพาะเจาะจงในการใช้โปรโมชัน "
        },
        {
            title: "",
            description: "ระบบเปิด-ปิดโปรโมชันแบบไดนามิก "
        },
        {
            title: "",
            description: "ระบบกำหนดโค๊ดสำหรับเรียกใช้โปรโมชัน "
        },
        {
            title: "",
            description: "กำหนดจำนวนครั้งที่โปรโมชันสามารถใช้งานได้ "
        },
    ],
    promotionHeaderEmpty: 'ระบบจัดการโปรโมชัน',
    promotionHeaderEmptyDesp: 'ระบบนี้ช่วยให้คุณสร้างและจัดการโปรโมชันได้อย่างมีประสิทธิภาพ เพื่อเพิ่มยอดขายและดึงดูดลูกค้าด้วยฟีเจอร์ที่ใช้งานง่าย และสามารถกำหนดเงื่อนไขต่างๆ ได้ตามความต้องการ',
    silomPOSPromotion: 'โปรโมชัน',
    confirmActivePromotion: 'คุณต้องการเปิดใช้งานโปรโมชันนี้ ใช่หรือไม่<br>โปรโมชันจะถูกนำกลับไปแสดงที่ POS และ ลูกค้าจะสามารถใช้งานโปรโมชันนี้ได้',
    confirmDeActivePromotion: 'คุณต้องการปิดใช้งานโปรโมชันนี้ ใช่หรือไม่<br>ลูกค้าจะไม่สามารถใช้งานโปรโมชันนี้ได้จนกว่าจะกลับมาเปิดใช้งานอีกครั้ง',
    maxPercenDiscount: 'ส่วนลดรวมสูงสุดที่เป็นไปได้คือ',
    usedPromotionBadge: 'ถูกใช้ไปแล้ว',
    usedPromotionBadgeDesp: 'ใบเสร็จ',
    promotionApplied: 'โปรโมชันนี้ถูกใช้งานไปแล้ว',
    limitQuota: 'จำกัดโควต้า',
    invalidQuotaInput: 'กำหนดจำนวนโควต้าไม่ถูกต้อง',
    'DINE_IN': 'ทานที่ร้าน',
    'PICK_UP': 'รับของที่ร้าน',
    'DELIVERY': 'จัดส่งเดลิเวอรี',
    'TAKE_AWAY': 'ซื้อกลับบ้าน',
    integratePromotionWith: 'เปิดใช้งานร่วมกับ',
    integratePromotionWithDesp: 'โปรโมชันนี้จะถูกบันทึกและใช้งานบนร้าน Grab',
    promotionUsageLimitPerCustomer: 'จำกัดการใช้โปรโมชันต่อคน',
    promotionUsageLimitDespPerCustomer: 'จำนวนสิทธิ์การใช้งานสูงสุดต่อผู้ใช้แต่ละคน',
    newCustomerOnly: 'ใช้ได้เฉพาะลูกค้าใหม่',
    newCustomerOnlyDesp: 'โปรโมชันนี้สำหรับลูกค้าที่ไม่เคยสั่งซื้อมาก่อน หรือไม่ได้กลับมาซื้อสินค้าภายในระยะเวลา 90 วันขึ้นไป เพื่อมอบสิทธิพิเศษให้กับลูกค้าใหม่เท่านั้น',
    timePerUser: 'ครั้งต่อคน',
    alertFreeItemGrab: 'สำหรับการสร้างโปรโมชัน Grab Food สามารถแถมฟรีสินค้าได้ 1 ชิ้นเท่านั้น',
    createPromotionGrabInfoHeader: 'โปรโมชันสำหรับ Grab Food',
    createPromotionGrabInfoDesp: 'ขอบเขตโปรโมชัน Grab Food ระบบจะบันทึกโปรโมชันไปที่ Grab Merchant ของท่าน และจะต้องปฏิบัติตามเงื่อนไขและข้อกำหนดของ Grab Food ดังนี้',
    grabPromotionGuidelines: {
        guideline1: 'เมื่อตั้งค่าขอบเขตใช้งานบน Grab Food แล้วไม่สามารถ ยกเลิกการใช้งานได้ในภายหลัง',
        guideline2: 'ไม่สามารถแก้ไขข้อมูลโปรโมชันได้ หลังจากโปรโมชันถูกเปิดใช้งาน',
        guideline3: 'สามารถแก้ไขรายละเอียดโปรโมชันได้ ก่อนเวลาเริ่มโปรโมชันอย่างน้อย 1 ชั่วโมง',
        guideline4: 'หากต้องการหยุดใช้งานโปรโมชัน Grab Food สามารถทำได้โดยการลบโปรโมชัน',
    },
    createPromotionInfoHeader: 'โปรโมชัน Silom POS',
    createPromotionInfoDesp: 'โปรโมชันสำหรับการใช้งานในร้าน Silom POS จะต้องปฏิบัติตามเงื่อนไขและข้อกำหนดดังนี้',
    promotionGuidelines: {
        guideline1: 'เมื่อสร้างโปรโมชันแล้ว สามารถแก้ไขรายละเอียดโปรโมชันได้ ก่อนเวลาเริ่มโปรโมชันอย่างน้อย 1 ชั่วโมง',
        guideline2: 'สามารถหยุดใช้งานชั่วคราวได้ โดยปิดสวิทซ์การใช้งานที่หน้ารายละเอียดโปรโมชัน',
    },
    onGoingPromotionDesp: 'อยู่ระหว่างช่วงเวลาโปรโมชัน ไม่สามารถแก้ไขข้อมูลได้',
    moreInfo: 'ดูข้อมูลเพิ่มเติม',
    support: 'รองรับ',
    grabPromotionTemplateDesp: 'เทมเพลตโปรโมชัน SilomPOS พร้อมรองรับการทำงานร่วมกับ Grab Food และปรับแต่งให้เหมาะกับธุรกิจของคุณ รองรับการตั้งค่าที่หลากหลาย เพื่อเพิ่มยอดขายและดึงดูดลูกค้าได้ง่ายยิ่งขึ้น',
    defineForGrabCampagin: 'ใช้สำหรับกำหนด Grab Campaign',
    saveFailedHeader: 'บันทึกไม่สำเร็จ',
    forGrabCampagin: 'เงื่อนไขนี้จะถูกพิจารณาเฉพาะออเดอร์ที่มาจาก Grab Food เท่านั้น',
    errorMessagesGrab: {
        '404Category': 'ไม่พบหมวดหมู่สินค้านี้บนร้าน Grab Food',
        '400InvalidParameters': 'ข้อมูลที่กรอกไม่ถูกต้อง กรุณาตรวจสอบและลองอีกครั้ง',
        '400CategoryOne': 'สามารถกำหนดส่วนลดได้สูงสุดเพียง 1 หมวดหมู่เท่านั้น',
        '400StartTime': 'กรุณาตั้งเวลาเริ่มต้นให้เป็นเวลา<br>หลังจากตอนนี้อย่างน้อย 15 นาที',
        '404Item': 'ไม่สินค้าที่กำหนดบนร้าน Grab Food',
        '400CampaignStartTime': 'ไม่สามารถแก้ไขได้กรณีใกล้เวลาเริ่มอย่างน้อย 1 ชั่วโมง',
        '400ItemMultiple': 'สินค้าที่กำหนดถูกใช้เป็นส่วนลดของโปรโมชันก่อนหน้าแล้ว',
        '400PromotionOverlaps': 'มีโปรโมชันที่คล้ายกันถูกสร้างไว้ก่อนหน้าแล้ว',
        '400InvalidQuotas': 'กำหนดโควต้าไม่ถูกต้อง',
        'unknownError': 'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ',
        'validateInfomation': 'กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน',
        '400CategoryCampaignOverlap': 'มีโปรโมชันส่วนลดหมวดหมู่นี้ถูกสร้างไว้ก่อนหน้าแล้ว'
    },
    createGrabPromotionDesp: 'เมื่อบันทึกโปรโมชันแล้ว สามารถแก้ไขโปรโมชันได้ก่อน<br>เวลาเริ่มต้นโปรโมชันอย่างน้อย 1 ชั่วโมง',
    invalidInput: 'กรอกข้อมูลไม่ถูกต้อง',
    invalidDate: 'วันที่และเวลาไม่ถูกต้อง',
    invalidDateDesp: 'วันเริ่มต้นจำเป็นต้องเริ่มหลังจาก ขณะนี้ อย่างน้อย 1 ชั่วโมง',
    grabPromotionDurationDesp: 'และเวลาเริ่มโปรโมชันจำเป็นต้องเริ่มหลังจาก ณ เวลานี้ไปอีกอย่างน้อย 15 นาที',
    templates: 'เทมเพลต',
    forSmartPlusPackage: 'สำหรับร้าน Grab และใช้แพ็คเกจ Smart+ ขึ้นไป',
    high: 'สูง',
    medium: 'ปานกลาง',
    low: 'ต่ำ',
    receiptDiscount: 'ส่วนลดใบเสร็จ',
    maxDiscount: 'สูงสุด',
    itemDiscountMapper: 'ส่วนลด',
    andMoreDiscount: 'และมีส่วนลดอื่น ๆเพิ่มเติม',
    categoryDiscount: 'ส่วนลดหมวดหมู่',
    specialPrice: 'ราคาพิเศษ',
    forUnderPrice: 'เฉพาะสินค้าที่ราคาไม่เกิน',
    freeItemMapper: 'แถมฟรี',
    whenPriceReceiptReach: 'เงื่อนไขเมื่อยอดบิลรวมมูลค่า',
    whenQuantityReceiptReach: 'เงื่อนไขเมื่อซื้อของรวมจำนวนทั้งบิล',
    exceedPrice: 'บาทขึ้นไป',
    exceedQuantity: 'ชิ้นขึ้นไป',
    whenBuySome: 'เงื่อนไขเมื่อซื้อ',
    forTotalPrice: 'รวมมูลค่า',
    forTotalQuantity: 'รวมจำนวน',
    whenBuySomeCategory: 'เงื่อนไขเมื่อซื้อของในหมวดหมู่',
    alertInAppBrowser: 'กรุณาเปิดหน้านี้ในเบราว์เซอร์อื่น (เช่น Chrome หรือ Safari) เพื่อประสบการณ์ที่ดีที่สุด',

    enterSixDigitCode: "กรอกรหัส 6 หลัก",
    notifications: "การแจ้งเตือน",
    setNotificationPreferences: "ตั้งค่าการแจ้งเตือนออเดอร์ และ กิจกรรมที่เกิดขึ้นภายในร้าน",
    remainingQuota: "โควต้าคงเหลือ",
    monthlyUsedQuota: "โควต้าที่ใช้เดือนนี้",
    dailyUsedQuota: "โควต้าที่ใช้วันนี้",
    dailyAverageQuota: "เฉลี่ยวันละ",
    topUpQuota: "เติมโควต้า",
    addNotificationChannel: "เพิ่มช่องทางการแจ้งเตือน",
    registerForNotifications: "ลงทะเบียนรับการแจ้งเตือน",
    enterReferenceCode: "กรอกรหัสอ้างอิง",
    enterReferenceCodeDescription: "โปรดป้อนรหัสอ้างอิงที่ได้รับจากระบบเพื่อดำเนินการต่อ",
    enterSixDigitSilomBot: "กรอกรหัส 6 หลักที่ได้จาก Silom Biz Alert",
    howToGetCode: "ดูวิธีการรับรหัสจากช่องทางต่าง ๆ",
    goToHelp: "ไปที่การช่วยเหลือ",
    languageSelection: "เลือกภาษาการแจ้งเตือน",
    deleteNotificationChannel: "ยกเลิกการเชื่อมต่อ",
    deleteNotificationPrompt: "คุณต้องการลบช่องทางการแจ้งเตือนหรือไม่?",
    deleteNotificationWarning: "หากคุณดำเนินการลบ คุณจะไม่ได้รับการแจ้งเตือนผ่านช่องทางนี้อีกต่อไป",
    salesNotification: "แจ้งเตือนการขายสินค้า",
    salesNotificationDescription: "ติดตามยอดขายแบบเรียลไทม์เมื่อมีการสั่งซื้อใหม่",
    closeShopReportNotification: "แจ้งเตือนพิมพ์สรุปปิดร้านประจำวัน",
    closeShopReportNotificationDescription: "ตรวจสอบยอดขายและสรุปรายงานประจำวันทุกครั้งที่มีการปิดร้าน",
    cashRoundNotification: "แจ้งเตือนรอบการขาย",
    cashRoundNotificationDescription: "รับข้อมูลเกี่ยวกับรอบการขายและยอดเงินสดที่ต้องตรวจสอบ",
    timeAttendanceNotification: "แจ้งเตือนเวลาเข้า-ออก พนักงาน",
    timeAttendanceNotificationDescription: "ตรวจสอบเวลาการเข้าและออกงานของพนักงานเพื่อการบริหารที่มีประสิทธิภาพ",
    selectPackage: "เลือกแพ็คเกจ",
    makePayment: "ชำระเงิน",
    pricingTitle: "แพ็คเกจราคา",
    sufficientPack: "Sufficient Pack",
    seamlessPack: "Seamless Pack",
    proPack: "Pro Pack",
    sufficientPackDescription: "เหมาะสำหรับร้านขนาดเล็ก ส่งข้อความอย่างมีประสิทธิภาพในราคาสุดคุ้ม!",
    seamlessPackDescription: "ตัวเลือกยอดนิยมสำหรับธุรกิจที่ต้องการส่งข้อความบ่อยในราคาที่ประหยัด!",
    proPackDescription: "สุดยอดแพ็คเกจสำหรับธุรกิจขนาดใหญ่ ส่งข้อความได้อย่างมืออาชีพและต่อเนื่อง!",
    packagePrice: "ราคา",
    averagePricePerMessage: "เฉลี่ยข้อความละ",
    paymentTerms: "เงื่อนไขการชำระเงิน",
    paymentMethods: "รองรับการชำระเงินผ่านบัตรเครดิต/เดบิต และโอนผ่านบัญชีธนาคาร",
    creditAddedWithin5Min: "เครดิตจะถูกเติมเข้าระบบภายใน 5 นาทีหลังจากชำระเงินสำเร็จ",
    contactSupportIfNoCredit: "หากไม่ได้รับเครดิต โปรดติดต่อฝ่ายบริการลูกค้า",
    noRefundOrChange: "ไม่สามารถขอคืนเงินหรือเปลี่ยนแพ็กเกจได้หลังจากการชำระเงินเสร็จสิ้น",
    cancelPayment: "ยกเลิกการชำระเงิน",
    message: "ข้อความ",
    starter: "เริ่มต้น",
    recommended: "แนะนำ",
    bestValue: "คุ้มค่า",
    incorrectRefCode: 'รหัสอ้างอิงไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง!',
    unknownError: 'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ',
    actiosHistory: 'ประวัติการทำรายการ',
    qrExpired: 'คิวอาร์หมดอายุ',
    qrPending: 'รอชำระ',
    regenerateQR: 'สร้าง QR Code ใหม่',
    paymentSuccessTitle: "ชำระเงินสำเร็จ",
    paymentSuccessful: "ขอบคุณสำหรับการสั่งซื้อของคุณ!",
    paymentSuccessDescription: "การชำระเงินของคุณเสร็จสมบูรณ์<br>ข้อมูลของคุณได้รับการอัปเดตเรียบร้อยแล้ว.",
    refCode: 'เลขอ้างอิง',
    'silom-alert': 'การแจ้งเตือน',
    "createLineGroup": "สร้างกลุ่มแชทใน LINE",
    "inviteFriendsAndAddBotLine": "เชิญเพื่อนและเพิ่มบอท Silom เข้าไปที่กลุ่มแชท",
    "botSendsRegistrationCode": "บอทจะส่งรหัสลงทะเบียน 6 หลัก",
    "ifNoCodeTypeRegister": "หากไม่ได้รับรหัสพิมพ์ 'ลงทะเบียน' เพื่อรับรหัสใหม่",
    "useCodeForNotifications": "ใช้รหัสนี้เพื่อลงทะเบียนรับการแจ้งเตือน",

    "createTelegramGroup": "สร้างกลุ่มแชทใน Telegram",
    "inviteFriendsAndAddBotTelegram": "เชิญเพื่อนและเพิ่มบอทโดยค้นหาชื่อ @silom_alert_bot เข้าไปที่กลุ่มแชท",
    "typeRegisterInGroup": "พิมพ์ '/register' ในกลุ่ม",
    "botSendsRegistrationCode": "บอทจะส่งรหัสลงทะเบียน 6 หลัก",
    "useCodeForNotifications": "ใช้รหัสนี้เพื่อลงทะเบียนรับการแจ้งเตือน",
    "notification": {
        "basic": "รับข้อความแจ้งเตือนพื้นฐาน",
        "requiresCredits": "ต้องเติมเครดิตข้อความ",
        "futureFeatures": "ฟีเจอร์พิเศษเพิ่มเติมในอนาคต",
        "professionalSuitable": "เหมาะกับร้านที่ต้องการระบบมืออาชีพ"
    },
    headerNotifyFeature:'เปรียบเทียบการแจ้งเตือนระหว่าง LINE และ Telegram',
    feature:'ฟีเจอร์',
    chooseYourBestPlatform:'เลือกแพลตฟอร์มที่เหมาะกับร้านคุณที่สุด',


}



export default messages
